import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Bounce, ToastContainer } from "react-toastify";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import api from "../../Services/Api/api";
import { joinSocketRoom } from "../../Services/Socket/socketListeners";
// import ProjectRoot from "../ProjectScreen/ProjectRoot";
import { initSocket } from "../../Services/Socket/socket";
import Roller from "../../Components/Loader/Roller/Roller";
import ProjectRoot from "../ProjectScreen/ProjectRoot";
import Notification from "../../Components/Notification/Notification";
import "react-toastify/dist/ReactToastify.css";

class RootScreen extends Component {
  state = {
    loading: false,
    error: null,
    reloadTimer: Date.now(),
    socketConnected: false,
  };

  componentDidMount() {
    // pushNotificationModule.startup(); // @TODO
    this.load().then(() => {
      this.initSocket();
    });
  }

  initSocket() {
    initSocket({
      onConnect: () => {
        this.setState({ socketConnected: Date.now() });

        if (this.projectId) {
          this.listenProjectUpdates();
        }
      },
    });
  }

  async load() {
    try {
      this.setState({ loading: true });

      let { project: projectData } = await api.get("v1/app/project/web");
      if (projectData) {
        const homeScreenId = projectData?.data?.homePage;
        const homeScreen = projectData?.screens?.find(
          (x) => x._id === homeScreenId
        );

        let screens = projectData.screens;
        if (homeScreen && screens) {
          screens = [
            homeScreen,
            ...screens.filter((x) => x._id !== homeScreen._id),
          ];
        }

        projectData = {
          ...projectData,
          screens,
        };
      }

      this.projectId = projectData?._id;
      this.props.setScreenState(
        { projectData, projectUpdated: Date.now() },
        true
      );
      this.setState({ loading: false });
    } catch (e) {
      console.warn(e);
      this.setState({ error: e.message, loading: false });
    }
  }

  listenProjectUpdates() {
    return joinSocketRoom(`project_${this.projectId}`)
      .then((x) => console.info("Listening project updates"))
      .catch((e) =>
        console.warn("Error listening to project update socket: " + e.message)
      );
  }

  render() {
    const projectData =
      this.projectId === this.props.projectData?._id
        ? this.props.projectData
        : null;

    const loading = (
      <div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: "gray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Roller />
      </div>
    );

    return (
      <>
        {projectData?.screens?.map ? (
          this.state.socketConnected ? (
            <ProjectRoot
              {...{
                projectData,
                key: this.state.reloadTimer.toString(),
              }}
            />
          ) : (
            loading
          )
        ) : this.state.loading ? (
          loading
        ) : (
          <div
            style={{ justifyContent: "center", alignItems: "center", flex: 1 }}
          >
            <div style={{ color: "#f00" }}>
              {this.state.error || "Error Loading App"}
            </div>
          </div>
        )}

        <Helmet>
          <title>{projectData?.name || ""}</title>
        </Helmet>
        <Notification />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </>
    );
  }
}

const SCREEN_NAME = "APP";
const mapStateToProps = (state) => ({
  projectData: state.pState.APP?.projectData,
  projectId: state.pState.APP?.projectId,
});
const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RootScreen);
