import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import "./style.css";

const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const AppxoloMediaPlayer = ({
  isAudioPlayer = false,
  src,
  autoPlay = false,
  showDuration = true,
  showFullscreenButton = true,
  showPlayButton = true,
  trackBarColor = "#594f4fff",
  trackColor = "#e15454ff",
  style = {},
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seekBarWidth, setSeekBarWidth] = useState(0);
  const videoRef = useRef(null);
  const seekBarRef = useRef(null);

  useEffect(() => {
    const mediaElement = videoRef.current;

    const updatePlayState = () => setIsPlaying(!mediaElement.paused);
    const updateMuteState = () => setIsMuted(mediaElement.muted);
    const updateFullScreenState = () => {
      const isFullScreenActive = document.fullscreenElement === mediaElement;
      setIsFullScreen(isFullScreenActive);
    };

    const updateCurrentTime = () => {
      setCurrentTime(mediaElement.currentTime);
      if (seekBarRef.current) {
        const progress =
          (mediaElement.currentTime / mediaElement.duration) * 100;
        setSeekBarWidth(progress);
      }
    };

    const handleLoadedMetadata = () => {
      setDuration(mediaElement.duration);
    };

    mediaElement.addEventListener("play", updatePlayState);
    mediaElement.addEventListener("pause", updatePlayState);
    mediaElement.addEventListener("volumechange", updateMuteState);
    mediaElement.addEventListener("timeupdate", updateCurrentTime);
    mediaElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    document.addEventListener("fullscreenchange", updateFullScreenState);

    if (autoPlay) {
      if (!window.isUserInteracted) {
        mediaElement.muted = true;
      }
      setTimeout(() => {
        mediaElement.play();
      }, 500);
    }

    return () => {
      mediaElement.removeEventListener("play", updatePlayState);
      mediaElement.removeEventListener("pause", updatePlayState);
      mediaElement.removeEventListener("volumechange", updateMuteState);
      mediaElement.removeEventListener("timeupdate", updateCurrentTime);
      mediaElement.removeEventListener("loadedmetadata", handleLoadedMetadata);
      document.removeEventListener("fullscreenchange", updateFullScreenState);
    };
  }, [autoPlay]);

  const handlePlayPause = () => {
    const mediaElement = videoRef.current;
    if (mediaElement.paused) {
      mediaElement.play();
    } else {
      mediaElement.pause();
    }
  };

  const handleMuteToggle = () => {
    const mediaElement = videoRef.current;
    mediaElement.muted = !mediaElement.muted;
  };

  const handleFullScreenToggle = () => {
    const mediaElement = videoRef.current;
    if (!document.fullscreenElement) {
      mediaElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleSeekBarClick = (e) => {
    const seekBar = seekBarRef.current;
    const rect = seekBar.getBoundingClientRect();
    const position = (e.clientX - rect.left) / rect.width;
    const newTime = position * duration;

    videoRef.current.currentTime = newTime;
    setSeekBarWidth(position * 100);
  };

  const playerClass = isAudioPlayer ? "awAudioPlayer" : "awVideoPlayer";

  return (
    <div
      className={`${playerClass} ${showDuration ? "" : "hideDuration"} ${
        showPlayButton ? "" : "hidePlay"
      } ${showFullscreenButton ? "" : "hideFullScreen"} ${
        isPlaying ? "playing" : "paused"
      } ${isMuted ? "muted" : ""}`}
      style={style}
    >
      <video
        ref={videoRef}
        className="awVideo"
        src={src}
        style={{ display: isAudioPlayer ? "none" : "block" }}
      ></video>
      <div className="awStatus">
        <img
          className="awStatusIco"
          src={
            isPlaying
              ? require("./img/stopButton.png")
              : require("./img/playButton.png")
          }
          alt="Play/Pause"
        />
      </div>
      <div onClick={handlePlayPause} className="awVideoHitBox" />
      <div className="awControls">
        <div className="awOptions">
          <div className="awOptionsBar left">
            <div className="awPlay" onClick={handlePlayPause}>
              <img
                className="awPlayIco pb"
                src={require("./img/playButton.png")}
                alt="Play"
                style={{ display: isPlaying ? "none" : "block" }}
              />
              <img
                className="awPlayIco sb"
                src={require("./img/stopButton.png")}
                alt="Pause"
                style={{ display: isPlaying ? "block" : "none" }}
              />
            </div>
            <div className="awDuration">
              <span>
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
            </div>
          </div>
          <div className="awOptionsBar right">
            <div
              className="awOptionItem fullScreenAction"
              onClick={handleFullScreenToggle}
            >
              <img
                className="fullScreenIco"
                src={require("./img/fullScreen.png")}
                alt="Full Screen"
              />
            </div>
            <div
              className="awOptionItem soundAction"
              onClick={handleMuteToggle}
            >
              <img
                className="awSoundIco son"
                src={require("./img/soundOn.png")}
                alt="Sound On"
                style={{ display: isMuted ? "none" : "block" }}
              />
              <img
                className="awSoundIco soff"
                src={require("./img/soundOff.png")}
                alt="Sound Off"
                style={{ display: isMuted ? "block" : "none" }}
              />
            </div>
          </div>
        </div>
        <div className="awMain">
          <div
            className="trackHitBox"
            onClick={handleSeekBarClick}
            ref={seekBarRef}
            style={{ cursor: "pointer" }}
          >
            <div
              className="awTrackBar"
              style={{
                backgroundColor: trackBarColor,
              }}
            >
              <div
                className="awTrack"
                style={{
                  width: `${seekBarWidth}%`,
                  backgroundColor: trackColor,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppxoloMediaPlayer;
