import React, { useState, useRef, useEffect } from "react";

const AudioRecorder = ({ style = {}, onChange }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [duration, setDuration] = useState(0); // In seconds
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];
      const mime_type = "audio/wav";

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: mime_type,
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        onChange({ url: audioURL, blob: audioBlob, mime_type });
        clearInterval(timerRef.current);
        timerRef.current = null;
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setDuration(0);

      timerRef.current = setInterval(() => {
        setDuration((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(timerRef.current);
      timerRef.current = null;

      // Stop all tracks to release the microphone
      const tracks = mediaRecorderRef.current.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const reset = () => {
    setAudioURL(null);
    setDuration(0);
    onChange({ url: null, blob: null });
  };

  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${mins}:${secs}`;
  };

  const actionLabel = isRecording ? "Stop" : audioURL ? "Reset" : "Record";

  const handleAwrAction = () => {
    return isRecording
      ? stopRecording()
      : audioURL
      ? reset()
      : startRecording();
  };

  return (
    <div style={{ ...style, ...styles.awAudioRecorder }}>
      <div style={styles.awrLeft}>
        <img
          src={require("../../Assets/img/recordMicrophone.png")}
          style={styles.awrMicIco}
        />
        <div style={styles.awrDuration}>
          {isRecording ? formatDuration(duration) : "00:00"}
        </div>
      </div>
      <div style={styles.awrAction} onClick={handleAwrAction}>
        {actionLabel}
      </div>
    </div>
  );

  return (
    <div
      style={{ ...style, display: "flex", alignItems: "center", gap: "10px" }}
    >
      <div>
        {!isRecording ? (
          <div onClick={startRecording} style={styles.grayDot}></div>
        ) : (
          <div onClick={stopRecording} style={styles.redDot}></div>
        )}
      </div>

      <div style={styles.duration}>
        {isRecording ? formatDuration(duration) : "0:00"}
      </div>

      <div>
        {isRecording ? (
          <div onClick={stopRecording} style={styles.stopButton}></div>
        ) : audioURL ? (
          <div onClick={reset} style={styles.resetButton}>
            ✖️
          </div>
        ) : (
          <div onClick={startRecording} style={styles.startButton}></div>
        )}
      </div>
    </div>
  );
};

const styles = {
  awAudioRecorder: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  awrLeft: {
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  awrMicIco: {
    width: "14px",
    height: "14px",
  },
  awrDuration: {
    fontSize: "12px",
    fontFamily: "system-ui",
  },
  awrAction: {
    fontSize: "12px",
    color: "#cd0a1f",
    fontFamily: "system-ui",
    cursor: "pointer",
  },
};

export default AudioRecorder;
