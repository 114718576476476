import React from "react";

import RenderInputBase from "./RenderInputBase";
import { RadioBox } from "../../../../appxolo-engine/components/react/Toggle";

class RenderRadio extends RenderInputBase {
  render() {
    const selectedValue = this.toString(this.valueObj?.selected?.value);
    const options = this.valueObj?.options?.value;

    const style = {
      ...(this.styles.input || {}),
      display: "flex",
      alignItems: "center",
    };

    return options?.map((item = {}) => {
      const checked = item.value === selectedValue;

      return (
        <RadioBox
          key={item.value}
          styles={{
            box: { ...style, backgroundColor: style["--box-color"] },
            activeBox: {
              backgroundColor: style["--activebox-color"] || "#ff0000",
            },
            inactiveBox: {
              backgroundColor: style["--inactivebox-color"] || "#bdbdbd",
            },
            label: { color: style["--label-color"] },
          }}
          value={checked}
          onChange={() =>
            this.onChange({
              selected: { value: item.value },
              value: item.value,
            })
          }
          label={item.value || ""}
        />
      );
    });
  }
}

export default RenderRadio;
