import _ from "lodash";

const exclusiveStyleKeys = [
  "borderRadius",
  "borderWidth",
  "borderColor",
  "borderStyle",
  "width",
  "height",
  "backgroundColor",

  "borderTopLeftRadius",
  "borderTopRightRadius",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",

  "borderTopWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderRightWidth",

  "borderTopStyle",
  "borderBottomStyle",
  "borderLeftStyle",
  "borderRightStyle",

  "borderTopColor",
  "borderBottomColor",
  "borderLeftColor",
  "borderRightColor",
];

const radioWrapperStyleKeys = [...exclusiveStyleKeys];

export const toggleStyle = {
  getMainWrapperStyle: (styles) => _.omit(styles, exclusiveStyleKeys),
  getCheckboxStyle: (styles) => _.pick(styles, exclusiveStyleKeys),

  getRadioWrapperStyle: (styles) => _.omit(styles, radioWrapperStyleKeys),
  getRadioboxStyle: (styles) => _.pick(styles, radioWrapperStyleKeys),
  getRadioInnerboxStyle: (styles) =>
    _.pick(styles, [
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderBottomLeftRadius",
      "borderBottomRightRadius",
    ]),
};
