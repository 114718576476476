import React from "react";

const Chevron = ({ className, ico, style = {} }) => {
  return (
    <div
      style={{
        width: "9px",
        height: "9px",
        borderRight: "1.5px solid black",
        borderTop: "1.5px solid black",
        transform: "rotate(135deg)",
        borderRadius: "2px",
        marginTop: "-2px",
        ...style,
      }}
    ></div>
  );
};

export default Chevron;
