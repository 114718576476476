import React, { Fragment, useEffect } from "react";

class ContainerTabs extends React.Component {
  state = {};

  indexMap = [];

  componentDidMount() {
    this.setDefaultActiveTab();
  }

  componentDidUpdate(prevProps) {
    const elementDataStore = this.getElementDataStore();

    if (elementDataStore?.value !== this.data?.value) {
      this.onValueChangedExternaly(elementDataStore?.value);
    } else if (
      elementDataStore?.activeContainerTabOrder !==
      this.externalActiveContainerTabOrder
    ) {
      this.externalActiveContainerTabOrder =
        elementDataStore?.activeContainerTabOrder;
      this.onIndexChangedExternaly(
        parseInt(elementDataStore?.activeContainerTabOrder)
      );
    }
  }

  setDefaultActiveTab() {
    this.setActiveTab({
      value: "",
    });
  }

  async handlePress(props, index) {
    // console.log("handle press: ", props, index, {
    //   value: await this.getContainerTabValue(props, index),
    //   activeContainerTab: {
    //     index: index,
    //     rowIndices: props.rowIndices,
    //     rowIds: props.rowIds,
    //     elementId: props.nodeData.id,
    //   },
    // });
    this.setActiveTab({
      value: await this.getContainerTabValue(props, index),
      activeContainerTab: {
        index: index,
        rowIndices: props.rowIndices,
        rowIds: props.rowIds,
        elementId: props.nodeData.id,
      },
    });
  }

  getAllChildProps() {
    const list = [];
    for (const key in this.childProps) {
      if (Object.prototype.hasOwnProperty.call(this.childProps, key)) {
        list.push(this.childProps[key]);
      }
    }
    return list;
  }

  onValueChangedExternaly(newValue) {
    const childProps = this.getAllChildProps();

    let valueFound = false;
    for (let i = 0; i < childProps.length; i++) {
      const { props, index } = childProps[i];

      const value = this.getContainerTabValue(props, index);
      console.log({ value });
      if (value === newValue) {
        valueFound = true;
        this.handlePress(props, index);
        break;
      }
    }

    if (!valueFound) {
      this.setActiveTab({ value: newValue, activeContainerTab: null });
    }
  }

  async findIndices(indexMap, n) {
    let count = 0;

    for (let i = 0; i < indexMap.length; i++) {
      for (let j = 0; j < indexMap[i].length; j++) {
        if (count === n) {
          return [i, j];
        }
        count++;
      }
    }

    throw new Error("n is out of range for the given index map.");
  }

  async onIndexChangedExternaly(index) {
    const childProps = this.getAllChildProps();

    const flatIndex = await this.findIndices(
      this.indexMap,
      parseInt(index)
    ).catch(() => null);

    let child = childProps[parseInt(flatIndex)];
    console.log({ childProps, this: this, index, flatIndex, child });

    if (child) {
      const { props, index } = child;
      this.handlePress(props, index);
    } else {
      this.setActiveTab({ value: "", activeContainerTab: null });
    }

    this.externalActiveContainerTabOrder = null;
  }

  childProps = {};

  async onChildLoad(props, index, rowIndex) {
    this.childProps[index + "-" + rowIndex] = { props, index, rowIndex };

    const containerTabsConfig = this.props.containerTabsConfig;
    const defaultActiveTab = containerTabsConfig?.defaultActiveTab;

    this.setNestedArrayValue(this.indexMap, index, rowIndex, true);
    // console.log(this.indexMap, index, rowIndex);

    if (defaultActiveTab && !this.defaultActiveTabIsSet) {
      if (isNaN(defaultActiveTab)) {
        const value = this.getContainerTabValue(props, index);
        if (defaultActiveTab == value) {
          this.handlePress(props, index);
          this.defaultActiveTabIsSet = true;
        }
      } else {
        const defaultIndex = parseInt(defaultActiveTab) - 1;
        if (index === defaultIndex) {
          this.handlePress(props, index);
          this.defaultActiveTabIsSet = true;
        }
      }
    }
  }

  getContainerTabValue(props, index) {
    let activeTabValueElementIds =
      this.props.containerTabsConfig?.activeTabValueElementIds
        ?.split?.(",")
        .map((x) => x.trim().replace(/^#/, ""))
        .filter((x) => !!x);

    let tabValueElementId,
      value = null;
    for (let i = 0; i < activeTabValueElementIds?.length; i++) {
      const elementId = activeTabValueElementIds[i];
      const childIndices = [
        0,
        ...(props.dom.findRecursiveIndices(elementId) || []),
      ];
      const isChild =
        elementId === props.domNode.id ||
        props.dom.isChild(props.indices, childIndices);

      if (isChild) {
        tabValueElementId = elementId;
        break;
      }
    }

    if (tabValueElementId) {
      const closestValueElement = props.dataStore.getClosestElement({
        targetElementId: tabValueElementId,
        getterIndices: props.indices,
        getterRowIndices: props.rowIndices,
      });
      value = closestValueElement?.value;
    }

    return value;
  }

  getElementDataStore() {
    return this.props.dataStore.data[this.props.domNode.id]?.[
      this.props.rowIds.join()
    ];
  }

  setActiveTab(data) {
    this.data = data || {};
    const elementDataStore = this.getElementDataStore();
    this.props.dataStore.mergeData({
      elementId: this.props.domNode.id,
      rowIndices: this.props.rowIndices,
      rowIds: this.props.rowIds,
      obj: {
        ...elementDataStore,
        ...data,
        activeContainerTabOrder: null,
        elementId: this.props.domNode.id,
        rowIds: this.props.rowIds,
        rowIndices: this.props.rowIndices,
        updatedAt: Date.now(),
      },
    });
  }

  getChildContainerTabsProps(props, activeContainerTab) {
    const active = !!(
      activeContainerTab &&
      activeContainerTab?.elementId === props.nodeData.id &&
      activeContainerTab?.rowIds?.join() === props?.rowIds?.join()
    );
    return {
      activeContainerTab,
      active,
      tabStatus: active ? "active" : "inactive",
    };
  }

  getLastElement(arr) {
    return arr?.[arr?.length - 1];
  }

  setNestedArrayValue(array, outerIndex = 0, innerIndex = 0, value = true) {
    array[outerIndex] = array[outerIndex] || [];

    for (let i = 0; i < outerIndex; i++) {
      if (array[i] === undefined) {
        array[i] = [];
      }
    }

    for (let i = 0; i <= innerIndex; i++) {
      if (array[outerIndex][i] === undefined) {
        array[outerIndex][i] = null;
      }
    }

    array[outerIndex][innerIndex] = value;

    return array;
  }

  render() {
    const {
      props: { style, children, domNode, rowIndices, rowIds },
    } = this;

    // const activeContainerTab = this.getElementDataStore()?.activeContainerTab;
    const activeContainerTab = this.data?.activeContainerTab;

    return (
      <div style={style}>
        {React.Children.map(children, (child, index) => {
          return {
            ...child,
            props: {
              ...child.props,

              intermediateProps: {
                mode: "containerTabsItem",
                index,

                render: (props) => {
                  const passedParameter = {
                    sourceType: "containerTabs",
                    elementId: domNode.id,
                    rowIndices: rowIndices,
                    rowIds,
                    containerTabs: this.getChildContainerTabsProps(
                      props,
                      activeContainerTab
                    ),
                  };

                  const passedParameters = [
                    ...props.passedParameters,
                    passedParameter,
                  ];

                  // props.setExtraStates({ passedParameter });

                  return (
                    <Fragment
                      {...{
                        key: props.rowIds.join(),
                      }}
                    >
                      {props.renderChildren({
                        passedParameters,
                        triggerPress: (...x) => {
                          this.handlePress(props, index);
                        },
                      })}
                      <TriggerOnLoad
                        onLoad={() =>
                          this.onChildLoad(
                            props,
                            index,
                            this.getLastElement(props.rowIndices)
                          )
                        }
                      ></TriggerOnLoad>
                    </Fragment>
                  );
                },
              },
            },
          };
        })}
      </div>
    );
  }
}

export const TriggerOnLoad = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onLoad();
    }, 0);

    setTimeout(() => {
      props.onLoad();
    }, 1000);

    setTimeout(() => {
      props.onLoad();
    }, 2500);

    setTimeout(() => {
      props.onLoad();
    }, 5000);
  }, []);
  return null;
};

export const TriggerOneTime = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.onLoad();
    }, 0);
  }, []);
  return null;
};

export default ContainerTabs;
