import React from "react";
import Carousel, {
  CarouselItem,
} from "../../../../Components/Carousel/Carousel";
import ContainerTabs from "./ContainerTabs";
import LongPressButton from "../../../../Components/etc/LongPressButton";
class RenderContainer extends React.Component {
  state = { hover: false, visible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true });
    }, 0);
  }

  hoverProps = {
    onMouseEnter: () => this.setState({ hover: true }),
    onMouseLeave: () => this.setState({ hover: false }),
  };

  renderChildren(containerProps) {
    const {
      props: { domNode, indices, RenderDomNode },
    } = this;

    return (domNode.children || []).map((domNode, i) => (
      <RenderDomNode
        {...{
          ...this.props,
          key: domNode.id,
          domNode,
          indices: [...indices, i],
          ...containerProps,
          immediateProps: null,
        }}
      />
    ));
  }

  processStyle() {
    const {
      props: { activeTab, domNode, passedParameters },
    } = this;

    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
    const hoverStyle = {
      ...(activeTabStyle || {}),
      ...(activeTab?.styleData?.hover || {}),
    };

    let style = this.state.hover ? hoverStyle : activeTabStyle;

    if (
      passedParameters?.filter(
        (x) => x.sourceType === "containerTabs" && x.containerTabs?.active
      ).length
    ) {
      let activeContainerTabStyle =
        activeTab?.styleData?.activeContainerTab || {};
      style = {
        ...style,
        ...activeContainerTabStyle,
      };
    }

    style = this.state.visible
      ? { opacity: 1, ...style }
      : { ...style, opacity: 0 };

    return style;
  }

  renderContainer(containerProps) {
    const {
      props: { domNode, activeTab, onPress, triggerPress, immediateProps },
    } = this;

    const style = this.processStyle();

    const touchable =
      triggerPress ||
      activeTab?.containerData?.linkings?.find((x) =>
        ["onPress", "onLongPress"].includes(x.triggerOn)
      );

    if (immediateProps?.mode === "carouselItem") {
      return (
        <CarouselItem
          {...immediateProps}
          immediateProps={{
            ...this.hoverProps,
            style: {
              ...style,
              ...(style?.width
                ? {
                    minWidth: style.width,
                    maxWidth: style.width,
                  }
                : {}),
              ...(style?.height
                ? {
                    minHeight: style.width,
                    maxHeight: style.width,
                  }
                : {}),
              // marginLeft: 0,
              // marginRight: 0,
              // marginTop: 0,
              // marginBottom: 0,
            },
          }}
        >
          {this.renderChildren(containerProps)}
        </CarouselItem>
      );
      return (
        <div
          style={{
            ...style,
            ...(style?.width
              ? {
                  minWidth: style.width,
                  maxWidth: style.width,
                }
              : {}),
          }}
          {...this.hoverProps}
        >
          {this.renderChildren(containerProps)}
        </div>
      );
    }

    const container = touchable ? (
      <LongPressButton
        onLongPress={() =>
          onPress({
            ...this.props,
            ...containerProps,
            triggerOn: "onLongPress",
          })
        }
        onShortPress={() => {
          if (triggerPress) triggerPress();
          onPress({
            ...this.props,
            ...containerProps,
            triggerOn: "onPress",
          });
        }}
        style={style}
        {...this.hoverProps}
      >
        {this.renderChildren(containerProps)}
      </LongPressButton>
    ) : (
      <div style={style} {...this.hoverProps}>
        {this.renderChildren(containerProps)}
      </div>
    );

    return container;
  }

  renderSlider(containerProps) {
    const {
      props: { domNode, activeTab },
    } = this;

    const activeTabStyle = this.processStyle();

    const sliderConfig = activeTab?.containerType?.sliderConfig || {};
    const containerTabsConfig = activeTab?.containerType?.containerTabsConfig;

    const sliderProps = {
      ...this.props,
      ...containerProps,
      containerTabsConfig,
      config: sliderConfig,
      style: activeTabStyle,
    };

    return (
      <Carousel {...sliderProps}>
        {this.renderChildren(containerProps)}
      </Carousel>
    );

    return (
      <Carousel {...sliderProps} key={sliderConfig.loadCompleted}>
        {this.renderChildren(containerProps)}
      </Carousel>
    );
  }

  renderContainerTabs(containerProps) {
    const {
      props: { domNode, activeTab },
    } = this;

    const activeTabStyle = this.processStyle();

    const containerTabsConfig = activeTab?.containerType?.containerTabsConfig;
    const containerTabsProps = {
      ...this.props,
      ...containerProps,
      containerTabsConfig,
      style: activeTabStyle,
    };

    return (
      <ContainerTabs {...containerTabsProps}>
        {this.renderChildren(containerProps)}
      </ContainerTabs>
    );
  }

  renderScreen() {
    const {
      props: { domNode, activeTab },
    } = this;

    const activeTabStyle = this.processStyle();

    const screenConfig = activeTab?.containerType?.screenConfig || {};
    const ScreenComponent = this.props.ScreenComponent;

    const passedParameters = [
      ...(screenConfig?.urlParameters?.map((item) => ({
        uid: Math.random(),
        urlParamName: item.parameterName?.value,
        value: item.parameterValue?.value,
        sourceType: "urlParam",
      })) || []),
      ...(this.props.passedParameters, []),
    ];

    return (
      <div style={activeTabStyle} {...this.hoverProps}>
        <ScreenComponent
          {...{
            ...this.props,
            ...screenConfig,
            screenId: screenConfig?.to,
            passedParameters,
          }}
        />
      </div>
    );
  }

  render() {
    const {
      props: { activeTab },
    } = this;

    const containerTypeData = activeTab?.containerType;

    if (containerTypeData?.type === "slider") {
      return this.renderSlider({});
    } else if (containerTypeData?.type === "containerTabs") {
      return this.renderContainerTabs({});
    } else if (containerTypeData?.type === "screen") {
      return this.renderScreen({});
    } else {
      return this.renderContainer({});
    }
  }
}

export default RenderContainer;
