import React from "react";
import MapContainer from "./MapContainer";
class RenderMap extends React.Component {
  render() {
    const {
      props: { domNode, activeTab, elementDataStore },
    } = this;
    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];

    // console.log({ elementDataStore, activeTab });

    return (
      <div style={{width: '100%', height: '100%'}}>
        <MapContainer
          {...{
            key:
              elementDataStore?.operations?.map((x) => x.operation).join(",") ||
              "emptymap",
            data: elementDataStore,
            onPress: this.props.onPress,
            style: activeTabStyle,
            theme: activeTab?.mapData?.theme || "standard",
          }}
        />
      </div>
    );
  }
}

export default RenderMap;
