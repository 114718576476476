import moment from "moment";
import { DateTime } from "luxon";

function parseDateFromString(dateString, format) {
  if (!isNaN(dateString)) {
    const parsedDate = moment(parseInt(dateString));
    if (parsedDate.isValid()) {
      return parsedDate.toISOString(true);
    }
  }

  if (format) {
    const parsedDate = moment(dateString, format, true); // Strict parsing in local time zone
    if (parsedDate.isValid()) {
      return parsedDate.toISOString(true); // Keep the local time zone information
    }
  }

  const formats = [
    "YYYY-MM-DD",
    "DD/MM/YYYY",
    "MM/DD/YYYY",
    "MMMM D, YYYY",
    "D MMMM YYYY",
    "HH:mm",
    "h:mm A",
    "HH:mm:ss",
    "h:mm:ss A",
    "HH:mm Z",
    "HH:mm:ss Z",
    "YYYY-MM-DDTHH:mm:ssZ",
    "YYYY-MM-DD HH:mm:ss",
    "YYYY-MM-DD h:mm:ss A",
    "MM/DD/YYYY HH:mm",
    "MM/DD/YYYY h:mm A",
    "MM/DD/YYYY HH:mm:ss",
    "MM/DD/YYYY h:mm:ss",
    "MMMM D, YYYY HH:mm:ss",
    "D MMMM YYYY HH:mm:ss Z",
  ];

  for (const format of formats) {
    const parsedDate = moment(dateString, format, true); // Strict parsing in local time zone
    if (parsedDate.isValid()) {
      return parsedDate.toISOString(true); // Keep the local time zone information
    }
  }

  // Attempt ISO format if no custom format matches
  const isoDate = moment(dateString);
  if (isoDate.isValid()) {
    return isoDate.toISOString(true);
  }

  return null;
}

const formatDate = (date, customFormat, formatType) => {
  let value;

  if (!date) {
    value = null;
  } else if (formatType === "timestamp") {
    value = new Date(date).getTime();
  } else if (formatType === "ISO" || customFormat === "ISO") {
    value = new Date(date).toISOString();
  } else if (formatType === "DURATION" || customFormat === "DURATION") {
    value = convertMillisecondsToDuration(Math.abs(new Date(date).getTime()));
  } else if (customFormat) {
    const dt = DateTime.fromJSDate(new Date(date));
    value = dt.toFormat(customFormat);
  }

  return value;
};

const timeToMilliseconds = (timeString) => {
  const regex =
    /(\d+)\s*(second|minute|hour|day|week|month|year|millisecond|sec|min|hr|ms)s?/i;
  const match = timeString.match(regex);

  if (!match) return null; // If no match is found

  const value = parseInt(match[1]);
  const unit = match[2].toLowerCase();

  // Convert to milliseconds based on the unit
  switch (unit) {
    case "millisecond":
    case "ms":
      return value;
    case "second":
    case "sec":
      return value * 1000;
    case "minute":
    case "min":
      return value * 60 * 1000;
    case "hour":
    case "hr":
      return value * 60 * 60 * 1000;
    case "day":
      return value * 24 * 60 * 60 * 1000;
    case "week":
      return value * 7 * 24 * 60 * 60 * 1000;
    case "month":
      return value * 30 * 24 * 60 * 60 * 1000; // Approximate a month as 30 days
    case "year":
      return value * 365 * 24 * 60 * 60 * 1000; // Approximate a year as 365 days
    default:
      return null;
  }
};

const convertMillisecondsToDuration = (ms) => {
  const units = [
    { label: "d", value: 24 * 60 * 60 * 1000 }, // Days
    { label: "h", value: 60 * 60 * 1000 }, // Hours
    { label: "m", value: 60 * 1000 }, // Minutes
    { label: "s", value: 1000 }, // Seconds
  ];

  for (const unit of units) {
    if (ms >= unit.value) {
      const value = Math.floor(ms / unit.value);
      return `${value}${unit.label}`;
    }
  }

  return `${ms}ms`; // If less than 1 second
};

const dateTimeModule = {
  parseDateFromString,
  formatDate,
  timeToMilliseconds,
};

export default dateTimeModule;
