import React from "react";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import PActions from "../../../../Stores/redux/Persisted/Actions";
import UnpActions from "../../../../Stores/redux/Unpersisted/Actions";
import { deviceId } from "../../../../Providers/deviceId";

const WebrtcMedia = (props) => {
  const { domNode, activeTab, onGoingCall, elementDataStore } = props;
  if (!onGoingCall) return null;

  const activeTabStyle =
    activeTab?.styleData?.[domNode?.value?.elementType] || {};

  // const data = elementDataStore;
  // const deviceId = data?.deviceId?.value;

  let streamData;

  // const deviceId = elementDataStore?.value;

  // if (deviceId === currentDeviceId)
  //   streamData = { stream: onGoingCall.localStream, isLocal: true };

  // if (!streamData)
  //   streamData = onGoingCall.remoteStreams?.find(
  //     (x) => x.peer?.deviceId === deviceId
  //   );

  let participantId = elementDataStore?.participantId?.value;
  let rtcLogMode = (elementDataStore?.mode || { value: "video" })?.value
    .toString()
    .toLowerCase()
    .trim();

  if (participantId === onGoingCall.participantId)
    streamData = {
      stream: onGoingCall.localStream,
      isLocal: true,
      peer: { deviceId: deviceId },
    };

  if (!streamData)
    streamData = onGoingCall.remoteStreams?.find(
      (x) => x.peer?.participantId === participantId
    );

  if (!streamData?.stream) return null;

  const peerLog = onGoingCall?.peerLog?.[streamData?.peer?.deviceId] || {};
  const streamMode = peerLog?.mode || "video";
  const streamMuted = peerLog?.mute === "mute";

  const mode =
    streamMode === "video"
      ? rtcLogMode
      : streamMode === "audio"
      ? ["audio", "none"].includes(rtcLogMode)
        ? rtcLogMode
        : streamMode
      : streamMode;

  return (
    <>
      {["audio", "none"].includes(mode) ? (
        <div
          style={{
            width: "100%",
            ...activeTabStyle,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50px",
              height: "50px",
              maxWidth: "90%",
              maxHeight: "90%",
              display: "flex",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255,255,255,0.40)",
            }}
          >
            {mode === "audio" ? (
              <AudioVisualizer
                stream={streamData.stream}
                style={{
                  width: "80%",
                  height: "80%",
                }}
              />
            ) : (
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  maxWidth: "80%",
                  maxHeight: "80%",
                }}
                src={
                  onGoingCall.permission === "video"
                    ? require("../../../../Assets/img/rtc/videoOff.png")
                    : require("../../../../Assets/img/rtc/audioOff.png")
                }
              />
            )}
          </div>
        </div>
      ) : null}

      {["audio", "video"].includes(mode) ? (
        <VideoView
          stream={streamData.stream}
          muted={!!streamData?.isLocal}
          style={{
            objectFit: "cover",
            width: "100%",
            ...activeTabStyle,
            display: mode !== "video" ? "none" : "block",
          }}
          streamMuted={streamMuted}
        />
      ) : null}
    </>
  );
};

class VideoView extends React.Component {
  state = {
    visible: !!this.props.stream,
  };
  videoView = React.createRef(null);

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.stream && this.props.stream) ||
      prevProps.stream !== this.props.stream
    ) {
      this.load();
    }
  }

  load() {
    // Pause the previous stream
    if (this.videoView.current?.srcObject) {
      this.videoView.current.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setTimeout(() => {
      this.videoView.current.srcObject = this.props.stream;
      this.videoView.current.onloadedmetadata = (e) => {
        this.videoView.current.play();
      };
    }, 0);
  }

  render() {
    const {
      props: { muted = false, style = {}, streamMuted },
    } = this;

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          ...style,
          position: "relative",
        }}
      >
        <video
          ref={this.videoView}
          autoPlay
          muted={muted}
          style={{ maxWidth: "100%", maxHeight: "100%", ...style }}
        ></video>

        {streamMuted ? (
          <img
            style={{
              position: "absolute",
              right: 20,
              bottom: 20,
              width: "30px",
              height: "30px",
            }}
            src={require("../../../../Assets/img/rtc/audioOff.png")}
          />
        ) : null}
      </div>
    );
  }
}

const SCREEN_NAME = "CALL_SCREEN";
const mapStateToProps = (state) => ({
  onGoingCall: state.vState[SCREEN_NAME]?.onGoingCall,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const AudioVisualizer = ({ stream, style }) => {
  const [barColor, setBarColor] = useState("yellow");

  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  const numPositions = [2, 1, 0, 4, 3];
  const numBars = numPositions.length; // Number of bars to represent voice

  // Draw a rounded rectangle
  function drawRoundedBar(ctx, x, y, width, height, radius) {
    radius = Math.min(radius, width / 2, height / 2);
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.fill();
  }

  useEffect(() => {
    const generateVibrantColorHex = () => {
      const dominant = Math.floor(Math.random() * 56 + 200);
      const secondary1 = Math.floor(Math.random() * 100 + 0);
      const secondary2 = Math.floor(Math.random() * 100 + 0);

      const colors = [dominant, secondary1, secondary2].sort(
        () => Math.random() - 0.5
      );

      const color = `#${colors
        .map((c) => c.toString(16).padStart(2, "0"))
        .join("")}`;

      return color;
    };

    setBarColor(generateVibrantColorHex());
  }, []);

  useEffect(() => {
    if (!stream) return;

    // Audio Context Setup
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    source.connect(analyser);

    // Optimize for voice frequencies
    analyser.fftSize = 512; // Better focus on voice frequency range
    analyser.smoothingTimeConstant = 0.85; // Smooth transitions without lag

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const smoothData = new Array(numBars).fill(0);

    const canvas = canvasRef.current;
    const canvasCtx = canvas?.getContext("2d");

    const applySmoothing = (currentValue, previousValue, smoothingFactor) => {
      const smoothedValue =
        previousValue * smoothingFactor + currentValue * (1 - smoothingFactor);

      // If the smoothed value is very close to zero, reset it
      return smoothedValue < 0.05 ? 0 : smoothedValue;
    };

    const getVocalFrequencyData = (dataArray) => {
      const sampleRate = audioContext.sampleRate; // Typically 44100 Hz
      const fftSize = analyser.fftSize; // 512 or 1024

      const frequencyResolution = sampleRate / fftSize; // Hz per bin

      // Define the vocal range (85 Hz to 1100 Hz)
      const voiceRangeStart = Math.floor(85 / frequencyResolution);
      const voiceRangeEnd = Math.ceil(4100 / frequencyResolution);

      return dataArray.slice(voiceRangeStart, voiceRangeEnd);
    };

    const draw = () => {
      requestAnimationFrame(draw);
      analyser.getByteFrequencyData(dataArray);

      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

      const voiceData = getVocalFrequencyData(dataArray);
      const totalVolume = voiceData.reduce((sum, value) => sum + value, 0);

      const barWidth = canvas.width / numBars;
      const centerY = canvas.height / 2;

      // If there's almost no volume, reset all bars
      // if (totalVolume < 1000) {
      //   for (let i = 0; i < numBars; i++) {
      //     smoothData[i] = 0;
      //   }
      //   return;
      // }

      for (let i = 0; i < numBars; i++) {
        const start = Math.floor((i / numBars) * voiceData.length);
        const end = Math.floor(((i + 1) / numBars) * voiceData.length);
        const segment = voiceData.slice(start, end);

        const average =
          segment.reduce((sum, value) => sum + value, 0) / segment.length || 0;

        const barHeight = (average / 255) * canvas.height * 0.5; // Emphasize voice amplitude

        // Smooth the bar height
        smoothData[i] = applySmoothing(barHeight, smoothData[i], 0.9);
        const calculatedHeight = Math.max(10, smoothData[i]);

        canvasCtx.fillStyle = barColor;

        const position = numPositions[i];
        drawRoundedBar(
          canvasCtx,
          position * barWidth + barWidth * 0.1, // Add spacing
          centerY - calculatedHeight,
          barWidth * 0.8, // Slightly narrower bars
          calculatedHeight * 2,
          999
        );
      }
    };

    const resizeCanvas = () => {
      if (containerRef.current) {
        canvas.width = containerRef.current.clientWidth * 3;
        canvas.height = containerRef.current.clientHeight * 3;
      }
      draw();
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      audioContext.close();
    };
  }, [stream, numBars, barColor]);

  return (
    <div ref={containerRef} style={{ ...style, overflow: "hidden" }}>
      <canvas ref={canvasRef} style={{ display: "block", zoom: 0.33 }} />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WebrtcMedia);
