import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { onMessageListener, requestForToken } from '../../Modules/firebase/firebase';
import { deviceId } from '../../Providers/deviceId';
import api from '../../Services/Api/api';

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const notify = () =>  toast(<ToastDisplay/>);
  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])

  // useEffect(()=>{
  //     requestForToken().then(token=>{
  //       return api
  //         .post("v1/device/", {
  //           deviceId: deviceId,
  //           clientType: "web",
  //           fcmToken: token,
  //         })
  //         .catch((e) => console.warn("Error updating fcm token: " + e.message));
  //     });
  // }, [])


  onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));

  return (
     <Toaster/>
  )
}

export default Notification