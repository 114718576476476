import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

import RootScreen from "./Views/Root/RootScreen";

import "bootstrap/dist/css/bootstrap.min.css";

import "rsuite/dist/rsuite.min.css";

import { persistor, store } from "./store";
export default class App extends Component {
  constructor(props) {
    super(props);

    if (!window.isUserInteracted) {
      window.isUserInteracted = false;
    }

    this.handleUserInteraction = this.handleUserInteraction.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleUserInteraction);
    document.addEventListener("keydown", this.handleUserInteraction);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleUserInteraction);
    document.removeEventListener("keydown", this.handleUserInteraction);
  }

  handleUserInteraction() {
    if (!window.isUserInteracted) {
      window.isUserInteracted = true;
      console.log("User interacted with the page for the first time!");
    }

    document.removeEventListener("click", this.handleUserInteraction);
    document.removeEventListener("keydown", this.handleUserInteraction);
  }
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RootScreen />
        </PersistGate>
      </Provider>
    );
  }
}
