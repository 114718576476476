import React, { useEffect } from "react";

const OutsideClickDetector = (props) => {
  const { targetRef, onOutsideClick, onEscPress } = props;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        onEscPress();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return null;
};

export default OutsideClickDetector;
