import React from "react";

import RenderInputBase from "./RenderInputBase";
import api from "../../../../Services/Api/api";
import { toast } from "react-toastify";

class RenderFileInput extends RenderInputBase {
  constructor(props) {
    super(props);
    this.pickerType = "file";
  }

  inputEl = React.createRef(null);

  async selectFile(files) {
    try {
      if (!files?.length) return;

      const fileLimit = this.props.activeTab?.inputData?.multiple
        ? parseInt(this.valueObj?.maximumImageCount?.value || 0) || 50
        : 1;

      const resultP = [];
      for (let i = 0; i < Math.min(files.length, fileLimit); i++) {
        const file = files[i];

        try {
          if (file.type?.match("video")) {
            await this.validateVideoDuration(file);
          } else {
            this.validateFileSize(file);
          }
        } catch (error) {
          console.warn(error.message);
          toast.error(error.message);
          continue; // Skip this file and continue with the next one
        }

        // Upload the valid file
        let buff = {};
        const result = api
          .media(
            "v1/file",
            { file },
            {
              onUploadProgress: (x) => console.info("on upload progress: ", x),
            }
          )
          .then((result) => {
            buff = result;
            return api.getFileLink(result.file, {
              params: { mime_type: result.file?.file_mime_type },
            });
          })
          .then((fullUrl) => ({
            value: fullUrl,
            valueObj: { ...buff, uri: fullUrl },
          }));
        resultP.push(result);
      }

      const result = await Promise.all(resultP);
      const existingValue = this.value;

      this.onChange({
        value: [...(existingValue?.length ? existingValue : []), ...result],
      });
    } catch (e) {
      console.warn(e);
    }
  }

  async validateVideoDuration(file) {
    let maxLength = parseFloat(this.valueObj?.maxLength?.value);
    maxLength = isNaN(maxLength) ? 0 : maxLength * 60; // convert minutes to second

    if (!maxLength) return true;
    return new Promise((resolve, reject) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        if (video.duration > maxLength) {
          reject(
            new Error(
              `Video duration exceeds the ${this.formatDuration(
                maxLength
              )} limit.`
            )
          );
        } else {
          resolve(true);
        }
      };

      video.onerror = () => {
        reject(new Error("Failed to load video file. Please try again."));
      };
    });
  }

  validateFileSize(file) {
    let maxSizeInMB = parseFloat(this.valueObj?.maxSize?.value);
    let maxSize = isNaN(maxSizeInMB) ? 0 : maxSizeInMB * 1024 * 1024;

    if (!maxSize) return true;
    if (file.size > maxSize) {
      throw new Error(`File size exceeds the ${maxSizeInMB}MB limit.`);
    }
  }

  formatDuration(seconds) {
    if (isNaN(seconds) || seconds < 0) {
      return "Invalid duration";
    }

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const parts = [];
    if (hours > 0) parts.push(`${hours} hour`);
    if (minutes > 0) parts.push(`${minutes} minute`);
    if (remainingSeconds > 0 || parts.length === 0)
      parts.push(`${remainingSeconds} second`);

    return parts.join(" ");
  }

  async deleteUpload(url) {
    try {
      const parsedUrl = new URL(url);
      const pathSegments = parsedUrl.pathname.split("/");
      const fileId = pathSegments[4];

      if (fileId) {
        await api.socket("v1/app/file/delete", { _id: fileId });
      }
    } catch (error) {
      console.warn(error);
    }
  }

  generateAcceptString(acceptFileTypes) {
    if (
      !acceptFileTypes ||
      acceptFileTypes.length === 0 ||
      acceptFileTypes.includes("all")
    ) {
      return ""; // Accept all files if empty or "all" is present
    }

    const acceptMapping = {
      photo: "image/*",
      video: "video/*",
    };

    return acceptFileTypes
      .filter((type) => acceptMapping[type])
      .map((type) => acceptMapping[type])
      .join(",");
  }

  render() {
    const style = this.styles?.input || {};
    const styles = this.staticStyles;

    const acceptString = this.generateAcceptString(
      this.props.activeTab?.inputData?.acceptFileTypes
    );

    const label = this.toString(this.valueObj?.label?.value);
    const renderedLabel = this.renderLabel({}, label || "+ Upload");

    const uploadedUrlData = this.value;

    const deleteItem = (i) => {
      this.deleteUpload(uploadedUrlData[i]?.value).catch(console.warn);

      const result = uploadedUrlData.filter((_, j) => i !== j);

      this.onChange({ value: result });
    };

    return (
      <div style={{ ...styles.container, ...style }}>
        <div
          style={{
            ...styles.uploadButton,
            backgroundColor:
              style["--button-color"] || styles.uploadButton?.backgroundColor,
          }}
          onClick={() => this.inputEl.current.click()}
        >
          {renderedLabel}
        </div>
        <div style={styles.uploadList}>
          {uploadedUrlData?.map?.((item, i) => (
            <div key={item.value} style={styles.uploadItem}>
              <div style={styles.closeButton} onClick={() => deleteItem(i)}>
                ×
              </div>
              <span style={styles.uploadText}>{renderedLabel}</span>
              {item.valueObj?.file?.file_mime_type?.match("image") ? (
                <img src={item.value} style={styles.image} />
              ) : (
                <span style={styles.uploadFileName}>
                  {item.valueObj?.file?.file_original_name || "file"}
                </span>
              )}
            </div>
          ))}
        </div>
        <input
          style={{ display: "none" }}
          type="file"
          accept={acceptString}
          ref={this.inputEl}
          defaultValue={""}
          onChange={(e) => this.selectFile(e.target.files)}
          multiple={!!this.props.activeTab?.inputData?.multiple}
          max={
            this.props.activeTab?.inputData?.multiple
              ? parseInt(this.valueObj.maximumImageCount?.value || 0)
              : 1
          }
          min={
            this.props.activeTab?.inputData?.multiple
              ? parseInt(this.valueObj?.minimumImageCount?.value || 0)
              : 1
          }
        />
      </div>
    );
  }

  staticStyles = {
    container: {
      width: "290px",
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      gap: "10px",
      height: "90px",
      backgroundColor: "#ffffff",
      padding: "10px",
      border: "1px solid #dbdce3",
      borderRadius: "8px",
    },
    uploadButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#eeeeee",
      borderRadius: "2px",
      padding: "0px 20px",
      whiteSpace: "nowrap",
      border: "1px solid transparent",
    },
    uploadList: {
      display: "flex",
      overflowX: "auto",
      gap: "10px",
    },
    uploadItem: {
      display: "flex",
      borderRadius: "2px",
      backgroundColor: "#eeeeee",
      position: "relative",
      border: "1px solid #eeeeee",
      alignItems: "center",
    },
    closeButton: {
      width: "16px",
      height: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "2px",
      fontSize: "10px",
      position: "absolute",
      top: "2px",
      right: "2px",
      lineHeight: "1",
      zIndex: 1,
      color: "#000000",
      border: "1px solid #eeeeee",
    },
    uploadText: {
      padding: "0px 20px",
      whiteSpace: "nowrap",
      opacity: 0,
    },
    uploadFileName: {
      left: "10px",
      right: "10px",
      position: "absolute",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#000000",
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "contain",
      position: "absolute",
      inset: 0,
    },
  };
}

export default RenderFileInput;
