import React from "react";

import RenderInputBase from "./RenderInputBase";
import api from "../../../../Services/Api/api";
import AudioRecorder from "../../../../Components/etc/AudioRecorder";

class RenderAudioRecorder extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  inputEl = React.createRef(null);

  async selectFile({ url, blob, mime_type }) {
    try {
      if (blob) {
        const result = await api.media(
          "v1/file",
          { file: blob },
          { onUploadProgress: (x) => console.info("on upload progress: ", x) }
        );

        const fullUrl = api.getFileLink(result.file, {
          params: { mime_type },
        });

        this.onChange({
          value: fullUrl,
          valueObj: { ...result, uri: fullUrl },
        });
      } else {
        this.onChange({ value: null, valueObj: null });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  render() {
    const style = {
      ...(this.styles?.input || {}),
    };

    return (
      <AudioRecorder style={style} onChange={this.selectFile.bind(this)} />
    );
  }
}

export default RenderAudioRecorder;
