import React, { Fragment } from "react";

import RenderNode from "../../../appxolo-engine/modules/calculation/execution/RenderNode";
import RenderContainer from "./Container/RenderContainer";
import RenderText from "./Text/RenderText";
import RenderInput from "./Input/RenderInput";
import RenderImage from "./Image/RenderImage";
import RenderMedia from "./Media/RenderMedia";
import RenderMap from "./Map/RenderMap";
import {
  subscribeSocketEvent,
  unsubscribeSocketEvent,
} from "../../../Services/Socket/socketListeners";
import InViewPort from "../../../Components/etc/InViewPort";

class RenderDomNode extends RenderNode {
  constructor(props) {
    super(props);

    this.state = { activeTab: null, loading: false, error: null };
    this.extraStates = {};
    this.intervals = [];
  }

  componentDidMount() {
    this.load().finally(() => {
      setTimeout(async () => {
        await this.load();
        setTimeout(() => {
          this.handlePress({ triggerOn: "onLoad" });
        }, 0);

        if (this.dependentDbTables.length) {
          this.changeStreamTableListener = subscribeSocketEvent(
            "/changeStream/table",
            (data) => {
              if (
                this.dependentDbTables.find(
                  (x) => x.dbId === data.dbId && x.tableId === data.tableId
                )
              ) {
                this.load({ disableCache: true });
              }
            }
          );

          this.localDatbaseSubscriptionId =
            this.props.databaseModule.localDatbaseSubscription.subscribe(() =>
              this.load({ disableCache: true })
            );
        }

        setTimeout(() => {
          this.load();
        }, 500);
      }, 500);
    });
  }

  componentWillUnmount() {
    this.clearIntervals();
    this.unsubscribeSubscriptions();
    this.removeTriggersFromDataStore();
    this.props.databaseModule.localDatbaseSubscription.unsubscribe(
      this.localDatbaseSubscriptionId
    );

    unsubscribeSocketEvent(
      "/changeStream/table",
      this.changeStreamTableListener
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.activeTab && this.state.activeTab) {
      this.props.onPress?.({ ...this.props, triggerOn: "onLoad" });
    }

    if (this.dependentUrlParams.length) {
      for (let i = 0; i < prevProps?.passedParameters?.length; i++) {
        const passedParameter = prevProps.passedParameters[i];
        if (
          passedParameter?.sourceType === "urlParam" &&
          this.dependentUrlParams.find(
            (x) => x.urlParamName === passedParameter.urlParamName
          )
        ) {
          const currentPassedParam = this.props.passedParameters.find(
            (x) =>
              x?.sourceType === "urlParam" &&
              x.urlParamName === passedParameter.urlParamName
          );

          if (currentPassedParam.value !== passedParameter.value) {
            this.load();
            break;
          }
        }
      }
    }

    if (this.periodicReloadMs && !this.periodicUpdateTimer) {
      const interval = this.periodicReloadMs;

      this.periodicUpdateTimer = setInterval(() => {
        if (!this.state.loading) this.load({ periodicReload: true });
      }, interval);
    } else if (!this.periodicReloadMs && this.periodicUpdateTimer) {
      clearInterval(this.periodicUpdateTimer);
    }

    if (
      this.props.reloadTrigger &&
      prevProps.reloadTrigger !== this.props.reloadTrigger
    ) {
      this.load();
    }
  }

  loadElementData =
    this.loadElementData[this.props.domNode?.value?.elementType];

  loadId = null;
  async load(options = {}) {
    const loadId = Date.now();
    try {
      this.loadId = loadId;
      this.setState({ loading: true, error: null });

      await this.loadNodeData();
      const activeTab = await this.getActiveTab();
      if (this.loadId === loadId) {
        this.setState({ activeTab });
      }

      let elementData;

      const prevElementData =
        this.props.dataStore.data[this.props.domNode.id]?.[
          this.props.rowIds.join()
        ];
      const prevActiveState = prevElementData?.activeTab;

      if (
        options.softLoad &&
        JSON.stringify(prevActiveState) === JSON.stringify(activeTab)
      ) {
        elementData = prevElementData;
      } else {
        elementData = await this.loadElementData(options);
      }

      // console.log({
      //   newElementData: elementData?.repeatingContainers?.length,
      //   sameLoadId: this.loadId === loadId,
      // });

      if (this.loadId === loadId) {
        this.props.dataStore.mergeData({
          elementId: this.props.domNode.id,
          rowIndices: this.props.rowIndices,
          rowIds: this.props.rowIds,
          indices: this.props.indices,
          obj: {
            ...elementData,
            activeTab: activeTab,
            updatedAt: Date.now(),
            elementType: this.props.domNode?.value?.elementType,
            elementId: this.props.domNode.id,
            rowIndices: this.props.rowIndices,
            rowIds: this.props.rowIds,
            indices: this.props.indices,
            triggers: {
              reload: () => this.load({ reload: true }),
            },
          },
        });

        this.subscribeELementChanges(() => {
          this.load().finally(() =>
            setTimeout(() => {
              if (!options.softLoad) this.load();
            }, 0)
          );
        });
        this.setState({ loading: false });

        return elementData;
      }
    } catch (e) {
      console.info(
        `Error in execution, ${this.props.domNode?.value?.elementType}#${this.props.domNode?.id} ${e.message}`
      );
      console.warn(e);
      if (this.loadId === loadId)
        this.setState({ loading: false, error: e.message });
    }
  }

  clearIntervals() {
    this.intervals.forEach(({ intervalId }) => clearInterval(intervalId));
  }

  removeTriggersFromDataStore() {
    const existingData =
      this.props.dataStore.data[this.props.domNode.id]?.[
        this.props.rowIds.join()
      ];

    this.props.dataStore.mergeData({
      elementId: this.props.domNode.id,
      rowIndices: this.props.rowIndices,
      rowIds: this.props.rowIds,
      obj: {
        ...existingData,
        triggers: null,
      },
    });
  }

  getLinkings() {
    const elementType = this.props.domNode?.value?.elementType;

    let linkings = ["container", "input"].includes(elementType)
      ? this.state.activeTab?.[elementType + "Data"]?.linkings
      : [];

    return linkings || [];
  }

  handleLinkings(linkings, options = {}) {
    return this.nodeData
      .handleLinkings(linkings, {
        ...options,
        addInterval: (x) => this.intervals.push(x),
      })
      .catch((e) => console.warn("Error handeling linkings: ", e.message));
  }

  async handlePress(options) {
    const triggerOn = options.triggerOn || "onPress";
    let linkings = this.getLinkings().filter((x) => x.triggerOn === triggerOn);

    if (options?.locationMark) {
      linkings = options.locationMark.linkings;
    }

    await this.handleLinkings(linkings, options);

    if (this.props.onPress) this.props.onPress(options);
  }

  setExtraStates(obj) {
    const changed =
      JSON.stringify(obj) !== JSON.stringify(this.extraStates || {});
    this.extraStates = obj;
    if (changed) {
      this.load({ softLoad: true });
    }
  }

  renderBasedOnElementType(elementType = "", propsToPass) {
    switch (elementType) {
      case "container":
        return <RenderContainer {...propsToPass} />;
      case "text":
        return <RenderText {...propsToPass} />;
      case "input":
        return <RenderInput {...propsToPass} />;
      case "image":
        return <RenderImage {...propsToPass} />;
      case "media":
        return <RenderMedia {...propsToPass} />;
      case "map":
        return <RenderMap {...propsToPass} />;
      default:
        return null;
    }
  }

  renderPaginationBar() {
    const containerData = this.state.activeTab?.containerData;

    if (
      containerData?.pagination?.valueType === "page" &&
      containerData?.repeating
    ) {
      return (
        <div>
          <button
            onClick={() => {
              if (!this.loadingRepeatingContainerRow) {
                this.setState(
                  {
                    pageNo: Math.max(1, (this.state.pageNo || 1) - 1),
                  },
                  () => this.load({ pageNo: this.state.pageNo })
                );
              }
            }}
          >
            Prev Page
          </button>
          {this.state.pageNo || 1}
          <button
            onClick={() => {
              if (!this.loadingRepeatingContainerRow) {
                this.setState(
                  {
                    pageNo: Math.max(1, (this.state.pageNo || 1) + 1),
                  },
                  () => this.load({ pageNo: this.state.pageNo })
                );
              }
            }}
          >
            Next Page
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  scrollStartMarker() {
    return null;
    // return <InViewPort callback={() => console.log("Scroll Start")} />;
  }

  scrollEndMarker({ repeatingData }) {
    const containerData = this.state.activeTab?.containerData;
    if (
      ["externalApiInfinite", "infinite"].includes(
        containerData?.pagination?.valueType
      ) &&
      !this.endReached &&
      !this.state.loading
    ) {
      return (
        <InViewPort
          callback={async () => {
            if (!this.loadingRepeatingContainerRow && !this.state.loading) {
              const elementData = await this.load({
                reload: false,
                pagination: containerData?.pagination,
                loadType: "pagination",
              });
              if (
                elementData?.repeatingContainers?.length ===
                repeatingData.length
              ) {
                this.endReached = true;
              }
            }
          }}
        />
      );
    } else {
      return null;
    }
  }

  render() {
    if (!this.state.activeTab) return null;

    const elementType = this.props.domNode?.value?.elementType;

    const elementDataStore =
      this.props.dataStore.data[this.props.domNode.id]?.[
        this.props.rowIds.join()
      ];

    const setElementDataStore = (data) => {
      return this.props.dataStore.mergeData({
        elementId: this.props.domNode.id,
        rowIndices: this.props.rowIndices,
        rowIds: this.props.rowIds,
        obj: {
          ...elementDataStore,
          ...data,
          updatedAt: Date.now(),
        },
      });
    };

    const repeatingData = ["container"].includes(elementType)
      ? elementDataStore?.repeatingContainers
      : [{ uid: "DEFAULT" }];

    return (
      <>
        {["container"].includes(elementType) && repeatingData?.length > 1
          ? this.scrollStartMarker({ repeatingData })
          : null}

        {repeatingData?.map((item, index) => {
          const rowIndices = [...this.props.rowIndices, index];
          const rowIds = [...this.props.rowIds, item.uid];
          const passedParameter = {
            sourceType: "repeatingContainer",
            elementId: this.props.domNode.id,
            rowIndices,
            rowIds,
            repeatingContainer: item,
          };
          const passedParameters = [
            ...(this.props.passedParameters || []),
            passedParameter,
          ];

          const propsToPass = {
            ...this.props,
            loading: this.state.loading,
            activeTab: this.state.activeTab,
            nodeData: this.nodeData,
            // key: this.state.activeTab?.uid,
            onPress: this.handlePress.bind(this),
            rowIndices,
            rowIds,
            passedParameters,
            intermediateProps: null,
            elementDataStore,
            setElementDataStore,
            reloadTrigger:
              (this.props.reloadTrigger || "") + "-" + JSON.stringify(item.row),
          };

          const fragment = (props = {}) => (
            <Fragment key={item.uid}>
              {this.renderBasedOnElementType(elementType, {
                ...propsToPass,
                ...props,
              })}
            </Fragment>
          );

          const intermediateProps = this.props.intermediateProps;

          // if (intermediateProps?.mode === "carouselItem") {
          //   return (
          //     <CarouselItem
          //       {...{
          //         key: `${intermediateProps.index}-${index}`,
          //         ...propsToPass,
          //         ...intermediateProps,
          //         onRender: intermediateProps.onRender(index),
          //       }}
          //     >
          //       {fragment()}
          //     </CarouselItem>
          //   );
          // } else
          if (["containerTabsItem"].includes(intermediateProps?.mode)) {
            return intermediateProps.render({
              ...propsToPass,
              setExtraStates: this.setExtraStates.bind(this),
              renderChildren: (props) => fragment(props),
            });
          } else {
            return fragment();
          }
        })}
        {["container"].includes(elementType)
          ? this.renderPaginationBar()
          : null}

        {["container"].includes(elementType) && repeatingData?.length > 1
          ? this.scrollEndMarker({ repeatingData })
          : null}
      </>
    );
  }
}

export default RenderDomNode;
