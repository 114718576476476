import React from "react";

import RenderInputBase from "./RenderInputBase";
import { Switch } from "../../../../appxolo-engine/components/react/Toggle";

class RenderSwitch extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  render() {
    const checked = this.toBoolean(this.value);

    const style = {
      ...(this.styles.input || {}),
      display: "flex",
      alignItems: "center",
    };

    return (
      <Switch
        styles={{
          box: { ...style, backgroundColor: style["--box-color"] },
          activeBox: {
            backgroundColor: style["--activebox-color"] || "#FF0000",
          },
        }}
        value={checked}
        onChange={(x) => this.onChangeValue(x)}
        label={this.valueObj?.placeholder?.value || ""}
      />
    );
  }
}

export default RenderSwitch;
