import React from "react";

import RenderInputBase from "./RenderInputBase";
import LongPressButton from "../../../../Components/etc/LongPressButton";

class RenderButton extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      props: { domNode, activeTab, onPress, triggerPress },
    } = this;

    return (
      <LongPressButton
        onLongPress={() =>
          onPress({
            ...this.props,
            triggerOn: "onLongPress",
          })
        }
        onShortPress={() => {
          if (triggerPress) triggerPress();
          onPress({
            ...this.props,
            triggerOn: "onPress",
          });
        }}
        style={Object.assign({ cursor: "pointer" }, this.styles?.input)}
        {...this.hoverProps}
      >
        <span style={{ color: this.styles?.input?.["--placeholder-color"] }}>
          {this.props.elementDataStore?.label?.value || "Button"}
        </span>
      </LongPressButton>
    );
  }
}

export default RenderButton;
