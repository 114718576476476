import React from "react";

import RenderInputBase from "./RenderInputBase";
import { Checkbox } from "../../../../appxolo-engine/components/react/Toggle";

class RenderCheckbox extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  render() {
    const checked = this.toBoolean(this.value);

    const style = {
      ...(this.styles.input || {}),
      display: "flex",
      alignItems: "center",
    };

    return (
      <Checkbox
        styles={{
          box: {
            ...style,
            backgroundColor: style["--box-color"],
          },
          activeBox: { backgroundColor: style["--activebox-color"] },
          check: { fill: style["--check-color"] },
          label: { color: style["--label-color"] },
        }}
        value={checked}
        onChange={(x) => this.onChangeValue(x)}
        label={this.valueObj?.placeholder?.value || ""}
      />
    );
  }
}

export default RenderCheckbox;
