import React from "react";
import RenderTextInput from "./RenderTextInput";
import RenderLocationInput from "./RenderLocationInput";
import RenderSelectBoxInput from "./RenderSelectBoxInput";
import RenderCheckbox from "./RenderCheckbox";
import RenderRadio from "./RenderRadio";
import RenderSwitch from "./RenderSwitch";
import RenderFileInput from "./RenderFileInput";
import RenderImageInput from "./RenderImageInput";
import RenderDateTimePicker from "./RenderDateTimePicker";
import RenderAudioRecorder from "./RenderAudioRecorder";
import RenderButton from "./RenderButton";

class RenderInput extends React.Component {
  render() {
    switch (this.props.activeTab?.inputType?.group) {
      case "dateTimePicker":
        return <RenderDateTimePicker {...this.props} />;
      case "imagePicker":
        return <RenderImageInput {...this.props} />;
      case "filePicker":
        return <RenderFileInput {...this.props} />;
      case "audioRecorder":
        return <RenderAudioRecorder {...this.props} />;
      case "switch":
        return <RenderSwitch {...this.props} />;
      case "checkBox":
        return <RenderCheckbox {...this.props} />;
      case "radio":
        return <RenderRadio {...this.props} />;
      case "selectBox":
        return <RenderSelectBoxInput {...this.props} />;
      case "locationInput":
        return <RenderLocationInput {...this.props} />;
      case "button":
        return <RenderButton {...this.props} />;
      case "textInput":
      case "textarea":
      case "number":
      case "password":
      default:
        return <RenderTextInput {...this.props} />;
    }
  }
}

export default RenderInput;
