import React, { Fragment, useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import ContainerTabs, {
  TriggerOneTime,
  TriggerOnLoad,
} from "../../Views/ProjectScreen/RenderElements/Container/ContainerTabs";

export const CarouselItem = (props) => {
  const {
    children,
    index,
    active,
    onRender,
    reportActive,
    onLoad,
    reloadActive,
    immediateProps = {},
  } = props;

  const elRef = useRef(null);

  useEffect(() => {
    if (active) reportActive(props);
  }, [active, reloadActive]);

  useEffect(() => {
    const element = elRef.current;

    if (!element) return;

    const getLayout = () => {
      const rect = elRef.current.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(elRef.current);

      const marginTop = parseFloat(computedStyle.marginTop);
      const marginBottom = parseFloat(computedStyle.marginBottom);
      const marginLeft = parseFloat(computedStyle.marginLeft);
      const marginRight = parseFloat(computedStyle.marginRight);

      const fullWidth = rect.width + marginLeft + marginRight;
      const fullHeight = rect.height + marginTop + marginBottom;

      return {
        clientHeight: fullHeight, //elRef?.current?.clientHeight,
        clientWidth: fullWidth, //elRef?.current?.clientWidth,}
      };
    };

    onRender(getLayout());

    const resizeObserver = new ResizeObserver(() => {
      onRender(getLayout());
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      ref={elRef}
      {...{
        style: {},
        ...immediateProps,
      }}
    >
      {children}
    </div>
  );
};

class Carousel extends ContainerTabs {
  state = {
    activeIndex: 0,
  };

  elRef = React.createRef(null);

  childData = new ChildDataProcessor(this.props);

  componentDidMount() {
    this.setDefaultActiveTab();

    if (this.elRef.current) {
      this.elRef.current.addEventListener("touchmove", this.onTouchMove, {
        passive: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.elRef.current) {
      this.elRef.current.removeEventListener("touchmove", this.onTouchMove);
    }
  }

  updateIndex(newIndex) {
    const { config } = this.props;
    const totalItems = this.childData.flatChildData.length;

    // console.log({ newIndex, totalItems, config, childData: this.childData });

    if (newIndex < 0) {
      newIndex = config?.loop ? totalItems - 1 : 0;
    } else if (newIndex >= totalItems) {
      newIndex = config?.loop ? 0 : totalItems - 1;
    }
    // console.log({ newIndex });

    this.setState({ activeIndex: newIndex });
  }

  handleDrag(e) {
    this.setState({ dragPosition: e });
  }

  handleDragEnd(dragPosition) {
    const relativePosition = {
      clientX: this.state.dragStart?.clientX - dragPosition?.clientX,
      clientY: this.state.dragStart?.clientY - dragPosition?.clientY,
    };

    const isVertical = this.props.style?.flexDirection === "column";

    const offset = relativePosition[isVertical ? "clientY" : "clientX"];

    const closestIndex = this.getClosestIndex({ offset, isVertical });
    // console.log({
    //   offset,
    //   isVertical,
    //   closestIndex,
    //   childData: this.childData,
    // });

    this.setState(
      { dragging: false, dragStart: null, dragPosition: null },
      () => {
        setTimeout(() => {
          this.updateIndex(closestIndex);
        }, 0);
      }
    );
  }

  getSnapPoints(childData, { isVertical }) {
    const key = isVertical ? "clientHeight" : "clientWidth";

    let arr = [0];
    let total = 0;
    for (let i = 0; i < childData.length; i++) {
      const layout = childData[i];
      total += layout?.[key] || 0;
      arr.push(total);
    }
    return arr;
  }

  getClosestIndex({ offset, isVertical }) {
    const snapPoints = [
      -Infinity,
      ...this.getSnapPoints(this.childData.flatChildData, { isVertical }),
      Infinity,
    ];

    const currentIndex = this.state.activeIndex;
    let snapPosition = snapPoints[currentIndex + 1] || 0;

    let sliderEl = this.elRef.current || {};
    snapPosition = Math.max(
      0,
      Math.min(
        snapPosition,
        sliderEl[isVertical ? "scrollHeight" : "scrollWidth"] -
          sliderEl.parentElement?.[isVertical ? "clientHeight" : "clientWidth"]
      )
    );

    let position = snapPosition + offset;

    let i = 0;
    while (position > snapPoints[i]) {
      ++i;
    }

    if (
      position > -50 &&
      position - snapPoints[i - 1] > snapPoints[i] - position
    )
      ++i;

    let index = i - 2;

    return index;
  }

  onValueChangedExternaly(newValue) {
    const childProps = this.getAllChildProps();

    for (let i = 0; i < childProps.length; i++) {
      const { props, index, rowIndex } = childProps[i];

      const value = this.getContainerTabValue(props, index);
      if (value === newValue) {
        const flatIndex = this.childData.findNthPosition(index, rowIndex);
        this.onIndexChangedExternaly(flatIndex);
        break;
      }
    }
  }

  async onIndexChangedExternaly(index) {
    if (
      this.state.activeIndex !== index &&
      index > -1 &&
      index < this.childData.flatChildData.length - 1
    )
      this.updateIndex(index);

    this.externalActiveContainerTabOrder = null;
  }

  getTranslateSize(index, { childData, key }) {
    let relativeValue = 0;
    if (
      this.state.dragging &&
      this.state.dragStart &&
      this.state.dragPosition
    ) {
      const relativePosition = {
        clientX:
          this.state.dragPosition?.clientX - this.state.dragStart?.clientX,
        clientY:
          this.state.dragPosition?.clientY - this.state.dragStart?.clientY,
      };
      const dict = { clientWidth: "clientX", clientHeight: "clientY" };
      const value = relativePosition[dict[key]];
      if (!isNaN(value)) relativeValue = parseInt(value * -1);
    }

    const dict = { clientWidth: "scrollWidth", clientHeight: "scrollHeight" };
    let total = 0;
    for (let i = 0; i < index; i++) {
      const element = childData[i];
      total += element?.[key] || 0;
    }

    let sliderEl = this.elRef.current || {};

    total = Math.max(
      0,
      Math.min(total, sliderEl[dict[key]] - sliderEl.parentElement?.[key])
    );

    total = total + relativeValue;

    return total * -1 + "px";
  }

  onTouchStart = (e) => {
    // e.preventDefault();
    setTimeout(() => {
      this.setState({
        dragStart: {
          clientX: e.touches[0].clientX,
          clientY: e.touches[0].clientY,
        },
        dragging: true,
        pause: true,

        mouseEvent: {
          a: "start",
          touch: e.touches[0],
          clientX: e.touches[0].clientX,
        },
      });
    }, 0);
  };

  onTouchMove = (e) => {
    e.preventDefault();
    if (this.state.dragging) {
      this.setState({
        dragPosition: {
          clientX: e.touches[0].clientX,
          clientY: e.touches[0].clientY,
        },
        mouseEvent: {
          a: "move",
          touch: e.touches[0],
          clientX: e.touches[0].clientX,
        },
      });
    }
  };

  onTouchEnd = (e) => {
    // e.preventDefault();

    setTimeout(() => {
      this.setState({ pause: false });
      if (this.state.dragging)
        this.handleDragEnd(this.state.dragPosition || {});
    }, 0);
  };

  render() {
    const {
      state: { activeIndex },
      props: { style, children, domNode, rowIndices, rowIds },
      childData,
    } = this;

    const isVertical = style.flexDirection === "column";

    // const activeContainerTab = this.getElementDataStore()?.activeContainerTab;
    const activeContainerTab = this.data?.activeContainerTab;

    return (
      <div>
        <div
          style={{
            ...(style || {}),
            overflow: "hidden",
            position: "relative",
            cursor: "grab",
            ...(!isVertical
              ? {
                  paddingLeft: 0,
                  paddingRight: 0,
                }
              : { paddingTop: 0, paddingBottom: 0 }),
            display: "block",
          }}
        >
          <div
            ref={this.elRef}
            style={{
              whiteSpace: "nowrap",
              display: "flex",
              flexDirection: isVertical ? "column" : "row",
              transition: this.state.dragging
                ? "transform 0.01s"
                : "transform 0.3s",
              // transform: `translate${
              //   isVertical ? "Y" : "X"
              // }(${this.getTranslateSize(activeIndex, {
              //   childData: this.childData.flatChildData,
              //   key: isVertical ? "clientHeight" : "clientWidth",
              // })})`,

              transform: `translate3d(${
                isVertical
                  ? `0, ${this.getTranslateSize(activeIndex, {
                      childData: this.childData.flatChildData,
                      key: "clientHeight",
                    })}, 0`
                  : `${this.getTranslateSize(activeIndex, {
                      childData: this.childData.flatChildData,
                      key: "clientWidth",
                    })}, 0, 0`
              })`,
              width: "100%",
              height: "100%",
              touchAction: "none",
            }}
            onMouseEnter={() => this.setState({ pause: true })}
            onMouseDown={(e) => {
              setTimeout(() => {
                this.setState({ dragStart: e, dragging: true });
              }, 0);
            }}
            onMouseMove={(e) =>
              e.buttons && this.state.dragging ? this.handleDrag(e) : null
            }
            onMouseUp={(e) => {
              setTimeout(() => {
                if (this.state.dragging) this.handleDragEnd(e);
              }, 0);
            }}
            onMouseLeave={(e) => {
              setTimeout(() => {
                this.setState({ pause: false });
                if (this.state.dragging) this.handleDragEnd(e);
              }, 0);
            }}
            onTouchStart={this.onTouchStart.bind(this)}
            // onTouchMove={(e) => {
            //   e.preventDefault();
            //   this.onTouchMove(e);
            // }}
            onTouchEnd={this.onTouchEnd.bind(this)}
          >
            {React.Children.map(children, (child, index) => {
              return {
                ...child,
                props: {
                  ...child.props,

                  intermediateProps: {
                    mode: "containerTabsItem",
                    index,

                    render: (props) => {
                      const passedParameter = {
                        sourceType: "containerTabs",
                        elementId: domNode.id,
                        rowIndices: rowIndices,
                        rowIds,
                        containerTabs: this.getChildContainerTabsProps(
                          props,
                          activeContainerTab
                        ),
                      };

                      const passedParameters = [
                        ...props.passedParameters,
                        passedParameter,
                      ];

                      // props.setExtraStates({ passedParameter });

                      const rowIndex = this.getLastElement(props.rowIndices);
                      const indexMapping = this.childData.mapping[activeIndex];
                      const active =
                        indexMapping?.groupIndex === index &&
                        indexMapping?.rowIndex === rowIndex;

                      return (
                        <Fragment
                          {...{
                            key: props.rowIds.join(),
                          }}
                        >
                          {props.domNode?.value?.elementType === "container" ? (
                            props.renderChildren({
                              passedParameters,
                              // triggerPress: (...x) => {
                              //   this.handlePress(props, index);
                              // },
                              immediateProps: {
                                ...props,
                                mode: "carouselItem",
                                onRender: (layout) =>
                                  this.childData.onChildRender(layout, {
                                    index,
                                    rowIndex,
                                    isVertical,
                                  }),
                                active: active,
                                reloadActive: null,
                                reportActive: (props) =>
                                  this.handlePress(props),
                              },
                            })
                          ) : (
                            <CarouselItem
                              {...{
                                ...props,

                                mode: "carouselItem",
                                onRender: (layout) =>
                                  this.childData.onChildRender(layout, {
                                    index,
                                    rowIndex,
                                    isVertical,
                                  }),
                                active: active,
                                reloadActive: null,
                                reportActive: (props) =>
                                  this.handlePress(props),
                              }}
                            >
                              {props.renderChildren({
                                passedParameters,
                                // triggerPress: (...x) => {
                                //   this.handlePress(props, index);
                                // },
                              })}
                            </CarouselItem>
                          )}

                          <TriggerOneTime
                            onLoad={() => {
                              this.childProps[index + "-" + rowIndex] = {
                                props,
                                index,
                                rowIndex: this.getLastElement(props.rowIndices),
                              };
                            }}
                          ></TriggerOneTime>
                        </Fragment>
                      );
                    },
                  },
                },
              };
            })}
          </div>

          <TriggerOnLoad
            onLoad={() => this.childData.fetchChildInfo()}
          ></TriggerOnLoad>
        </div>
      </div>
    );
  }
}

class ChildDataProcessor {
  constructor(props) {
    this.props = props;
  }

  childData = [];
  childCounts = [];
  flatChildData = [];
  mapping = {};

  findNthPosition(index, elementIndex) {
    const childCounts = this.childCounts;
    if (index < 0 || index >= childCounts.length) {
      throw new Error("Invalid index provided.");
    }
    if (elementIndex < 0 || elementIndex >= childCounts[index]) {
      throw new Error("Invalid elementIndex for the given index.");
    }

    let nth = 0;

    for (let i = 0; i < index; i++) {
      nth += childCounts[i];
    }

    nth += elementIndex;

    return nth;
  }

  fetchChildInfo() {
    let childCounts = this.props.children.map((child) => {
      const id = child.props.domNode.id;
      const dataStore =
        this.props.dataStore.data[id][child.props.rowIds.join(",")];
      const repeatingContainers = (dataStore?.elementType === "container" &&
        dataStore?.repeatingContainers) || [{}];
      return repeatingContainers?.length;
    });
    this.childCounts = childCounts;
  }

  onChildRender(layout, { index, rowIndex = 0, isVertical }) {
    this.childData = update(this.childData, {
      $merge: {
        [index]: update(this.childData[index] || [], {
          $merge: {
            [rowIndex || 0]: layout,
          },
        }),
      },
    });

    const { flatChildData, mapping } = this.processChildData();
    this.flatChildData = flatChildData;
    this.mapping = mapping;
  }

  processChildData() {
    let flatChildData = [];
    let mapping = {};
    let flatIndex = 0;

    this.childCounts.forEach((count, groupIndex) => {
      const groupData = this.childData[groupIndex] || [];

      for (
        let rowIndex = 0;
        rowIndex < Math.max(count, groupData?.length);
        rowIndex++
      ) {
        // Use 0 if groupData[rowIndex] is undefined
        const value = groupData[rowIndex];
        flatChildData.push(value);
        // console.log({ rowIndex, count, value, groupData, flatChildData });

        // Map flattened index to groupIndex and rowIndex
        mapping[flatIndex] = {
          groupIndex,
          rowIndex,
        };

        flatIndex++;
      }
    });

    // console.log({
    //   flatChildData,
    //   this: this,
    //   childCounts: JSON.stringify(this.childCounts),
    //   childData: JSON.stringify(this.childData),
    // });

    return {
      flatChildData,
      mapping,
    };
  }
}

export default Carousel;
