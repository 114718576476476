import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { Line, Bar, Pie, Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
  scales: {
    x: {
      display: true,
      offset: true, // Adds space on each side of the x-axis items
      grid: {
        display: false, // Optional: remove grid lines on the x-axis
        borderWidth: 0,
      },
      border: {
        display: false, // Removes the x-axis border line
      },
      ticks: {
        color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
        font: {
          family: "Avenir",
          size: 12, // Optional: Set font size for x-axis labels
        },
      },
    },
    y: {
      display: true,
      grid: {
        display: false, // Removes the horizontal grid lines along the y-axis
        borderWidth: 0,
      },
      border: {
        display: false, // Removes the x-axis border line
      },
      ticks: {
        color: "#bdbdbd", // Set the color for x-axis labels (e.g., red)
        font: {
          family: "Avenir",
          size: 12, // Optional: Set font size for x-axis labels
        },
      },
      min: 0,
    },
  },
  hover: {
    mode: "nearest", // Ensures the hover mode is set to detect nearest points
    intersect: false, // Ensures it only triggers when directly over a point
  },
};

const defaultDataConfig = {
  tension: 0.01,
  pointRadius: 5,
  pointBorderColor: "rgba(255,255,255,0.0)",
  pointBackgroundColor: "rgba(255,255,255,0.0)",
  pointHoverBorderColor: "#5f7efb",
  pointHoverBackgroundColor: "#5f7efb",
  pointHoverRadius: 5,
  hoverRadius: 5,
  pointHoverBorderWidth: 5,
  borderCapStyle: "round",
  clip: false,
};

class ChartMediaInner extends React.PureComponent {
  render() {
    const { elementDataStore } = this.props;

    if (elementDataStore?.chartType === "line") {
      return <LineChart {...this.props} />;
    } else if (elementDataStore?.chartType === "bar") {
      return <BarChart {...this.props} />;
    } else if (elementDataStore?.chartType === "pie") {
      return <PieChart {...this.props} />;
    } else {
      return null;
    }
  }
}

const ChartMedia = (props) => {
  const { domNode, activeTab, elementDataStore } = props;
  return <ChartMediaInner {...{ domNode, activeTab, elementDataStore }} />;
};

function LineChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => {
      const color = dataset?.borderColor?.value?.[0] || "rgb(0, 0, 255)";
      return {
        ...defaultDataConfig,
        labels: "",
        data: dataset?.chartData?.value?.length
          ? dataset?.chartData?.value
          : [0],
        borderColor: color,
        pointHoverBorderColor: color,
        pointHoverBackgroundColor: color,
        borderWidth: 4,
      };
    }),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Line options={options} data={linedata} />;
    </div>
  );
}

function BarChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => {
      const color = dataset?.backgroundColor?.value || ["rgb(0, 0, 255)"];
      return {
        ...defaultDataConfig,
        labels: "",
        data: dataset?.chartData?.value?.length
          ? dataset?.chartData?.value
          : [0],
        backgroundColor: color,
        borderColor: color,
        pointHoverBorderColor: color,
        pointHoverBackgroundColor: color,
      };
    }),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Bar options={options} data={linedata} />;
    </div>
  );
}

function PieChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => {
      const color = dataset.backgroundColor?.value?.length
        ? dataset.backgroundColor?.value
        : chartColors;

      return {
        ...defaultDataConfig,
        labels: "",
        data: dataset?.chartData?.value?.length
          ? dataset?.chartData?.value
          : [0],
        backgroundColor: color,
        hoverBackgroundColor: color,
        backgroundColor: color,
        hoverBackgroundColor: color,
        pointHoverBorderColor: color,
        pointHoverBackgroundColor: color,
        borderColor: "#FFFFFF",
        borderWidth: 2,
        // spacing: 2,
      };
    }),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Doughnut options={{ ...options, scales: null }} data={linedata} />;
    </div>
  );
}

const chartColors = [];
const getRandomHexColor = () => {
  const randomNumber = Math.floor(Math.random() * 16777215);
  const hexColor = `#${randomNumber.toString(16).padStart(6, "0")}`;
  return hexColor;
};

for (let i = 0; i < 100; i++) {
  chartColors.push(getRandomHexColor());
}

export default ChartMedia;
