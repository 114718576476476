import _ from "lodash";
import UnpersistedActions from "../../Stores/redux/Unpersisted/Actions";
import { store } from "../../store";
import api from "../../Services/Api/api";

const SCREEN_NAME = "CALL_SCREEN";

const getUnpersistedState = () => store?.getState().vState?.[SCREEN_NAME];

const setUnpersistedState = (obj) => {
  store.dispatch(UnpersistedActions.setVScreenState(SCREEN_NAME, obj || {}));
};

const joinMeeting = async (data, opt) => {
  console.log("joining meeting: ", data);

  let { rtcroom, roomId, participantId, roomType, streamControl, permission } =
    data;

  if (!rtcroom) {
    roomId = roomId?.trim();

    if (!roomId) throw new Error("Invalid room Id " + roomId);

    const res = await api.post("v1/webrtc/meeting/getorcreate", {
      shortId: roomId,
      password: "DEFAULT",
      roomType,
    });
    if (res.rtcroom) rtcroom = res.rtcroom;
    else throw new Error("Something went wrong");
  }

  const onGoingCall = getUnpersistedState()?.onGoingCall;

  if (
    onGoingCall &&
    (onGoingCall.rtcroom?._id || onGoingCall.rtcroom)?.toString() !=
      (rtcroom?._id || rtcroom)?.toString()
  ) {
    // send busy tone
    window.alert("Call in progress");
  } else {
    setUnpersistedState({
      onGoingCall: {
        ...(onGoingCall?.permission === permission ? onGoingCall || {} : {}),
        rtcroom,
        roomType: rtcroom.roomType,
        participantId,
        streamControl,
        permission,
        eventType: "webrtc-roominvite",
        visible: true,
        autoAnswer: true,
      },
    });
  }
};

const sleep = (ms) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve(true);
    }, ms)
  );
};

const initiateCall = async (data) => {
  const onGoingCall = getUnpersistedState()?.onGoingCall;

  if (onGoingCall) {
    // Toast.show('Call in progress');
    throw new Error("Call in progress");
  } else {
    const onGoingCall = {
      data,
      callType: "call",
      visible: true,
    };

    setUnpersistedState({
      onGoingCall,
    });
  }
};

const onCallRequest = async (data) => {
  console.log("onCallRequest: ", data);
  const { rtcroom } = data;
  const onGoingCall = getUnpersistedState()?.onGoingCall;

  if (onGoingCall) {
    // TODO send busy signal
    throw new Error("Call in progress");
  } else {
    const onGoingCall = {
      callType: "call",
      visible: true,
      rtcroom,
    };

    setUnpersistedState({
      onGoingCall,
    });
  }
};

const createMeeting = async (config = {}) => {
  const onGoingCall = getUnpersistedState()?.onGoingCall;

  if (onGoingCall) {
    // Toast.show('Call in progress');
    throw new Error("Call in progress");
  } else {
    const { rtcroom } = await api.post("v1/webrtc/meeting/create", {
      ...config,
    });

    return { rtcroom };
  }
};

const endCall = async () => {
  setUnpersistedState({
    onGoingCall: null,
  });
};

const getOnGoingCallData = async () => {
  return getUnpersistedState()?.onGoingCall;
};

export default {
  joinMeeting,
  initiateCall,
  onCallRequest,
  createMeeting,
  endCall,
  getOnGoingCallData,
};
