import _processStyle from "./processStyle.js";
const { processStyle } = _processStyle;

const preset = {
  container: {
    default: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "20px",
      paddingBottom: "20px",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },
  },
  text: {
    default: {
      fontSize: "14px",
      fontWeight: "normal",
    },
  },
  image: {
    default: {
      width: "150px",
      height: "150px",
      // backgroundColor: "#e5e5e5",
    },
  },
  input: {
    default: {
      width: "100%",
      height: "44px",
      paddingTop: "10px",
      paddingBottom: "10px",
      paddingLeft: "15px",
      paddingRight: "15px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderTopColor: "#d6d6d6",
      borderBottomColor: "#d6d6d6",
      borderLeftColor: "#d6d6d6",
      borderRightColor: "#d6d6d6",
    },
    textInput: {},
    textarea: {},
    number: {},
    password: {},
    locationInput: {},
    selectBox: {},
    checkBox: {
      width: "24px",
      height: "24px",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderTopColor: "#e8e8e8",
      borderBottomColor: "#e8e8e8",
      borderLeftColor: "#e8e8e8",
      borderRightColor: "#e8e8e8",
      "--box-color": "#FFFFFF",
      "--activebox-color": "#000000",
      "--check-color": "#ffffff",
      "--label-color": "#000000",
    },
    radio: {
      width: "24px",
      height: "24px",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: "#FFFFFF",
      borderTopLeftRadius: "999px",
      borderTopRightRadius: "999px",
      borderBottomLeftRadius: "999px",
      borderBottomRightRadius: "999px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderTopColor: "#e8e8e8",
      borderBottomColor: "#e8e8e8",
      borderLeftColor: "#e8e8e8",
      borderRightColor: "#e8e8e8",
      "--box-color": "#ffffff",
      "--inactivebox-color": "#eeeeee",
      "--activebox-color": "#000000",
      "--label-color": "#000000",
    },
    switch: {
      width: "50px",
      height: "28px",
      paddingLeft: "4px",
      paddingRight: "4px",
      paddingTop: "4px",
      paddingBottom: "4px",
      backgroundColor: "#FFFFFF",
      borderTopLeftRadius: "999px",
      borderTopRightRadius: "999px",
      borderBottomLeftRadius: "999px",
      borderBottomRightRadius: "999px",
      borderTopWidth: "1px",
      borderBottomWidth: "1px",
      borderLeftWidth: "1px",
      borderRightWidth: "1px",
      borderTopStyle: "solid",
      borderBottomStyle: "solid",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderTopColor: "#e8e8e8",
      borderBottomColor: "#e8e8e8",
      borderLeftColor: "#e8e8e8",
      borderRightColor: "#e8e8e8",
      "--box-color": "#FFFFFF",
      "--activebox-color": "#e8e8e8",
    },
    imagePicker: {},
    filePicker: {},
    dateTimePicker: {},
  },
  map: {
    default: {
      width: "100%",
      height: "300px",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
  media: {
    default: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    audio: {
      height: null,
    },
    video: {
      height: "200px",
    },
    chart: {
      width: "400px",
      height: "200px",
    },
    webrtc: {
      height: "200px",
    },
  },
};

const getDefaultElementStyles = (elementType, varient) => {
  const styles = preset[elementType];
  const varientStyle = Object.assign({}, styles?.default, styles?.[varient]);
  return processStyle(varientStyle);
};

const includeDefaultElementStyles = (styles, elementType, varient) => {
  const defaultStyles = getDefaultElementStyles(elementType, varient);
  const result = {};

  for (const key in defaultStyles) {
    if (Object.prototype.hasOwnProperty.call(defaultStyles, key)) {
      result = {
        ...result,
        [key]: Object.assign({}, defaultStyles[key], styles[key]),
      };
    }
  }

  return result;
};

const getElementVarient = (element, activeTab) => {
  const elementType = element?.value?.elementType;
  if (!activeTab) {
    const data = element.value?.data || {};
    activeTab = data?.tabs?.[data?.activeTabIndex || 0] || {};
  }

  if (elementType === "media") {
    return activeTab?.mediaData?.mediaType || "video";
  } else if (elementType === "input") {
    return activeTab?.inputType?.group || "textInput";
  }
};

const defaultElementStyles = {
  get: getDefaultElementStyles,
  include: includeDefaultElementStyles,
  getVarient: getElementVarient,
};

export default defaultElementStyles;
