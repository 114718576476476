class ScreenDataStore {
  constructor({ dom, storeData }) {
    this.id = Math.random();
    this.dom = dom;
    this.save = storeData;
  }

  /**
   * {
   *    [elementId] : {
   *        [rowIds.join()] : {
   *            repeatingContainers?: [{
   *               row: {},
   *               ...rest
   *            }],
   *            valueObj: any
   *            valueType: any
   *            value: any
   *            activetab?: Object
   *        }
   *    }
   * }
   */
  data = {};

  /**
   * {
   *   [apiRequestId]: {
   *      elementId: String,
   *      overideJSON: ExternalApiConfigData
   *   }
   * }
   */
  externalApiRequestIds = {};

  /**
   * [{
   *    elementId: string
   *    rowIndices: string,
   *    rowIds: string,
   *    subscribers: [{
   *        elementId: string,
   *        nodeId: string,
   *        customId: string, // to prevent duplicate subscriber from same place yet to allow subscriber from different place of same node id
   *        fn: Function
   *    }]
   * }]
   */
  elementChangesListeners = [];

  // elementId:string,
  mergeData({ elementId, rowIds, obj }) {
    const existing = this.data[elementId]?.[rowIds?.join?.()];

    this.data = {
      ...this.data,
      [elementId]: {
        ...(this.data[elementId] || {}),
        [rowIds?.join?.()]: {
          ...(this.data[elementId]?.[rowIds?.join?.()] || {}),
          ...obj,
        },
      },
    };

    // remove duplicate and old element instance with same row-indices
    for (const rowIdsStr in this.data[elementId]) {
      if (Object.hasOwnProperty.call(this.data[elementId], rowIdsStr)) {
        const elementInstance = this.data[elementId][rowIdsStr];
        if (
          obj.rowIndices &&
          elementInstance.rowIndices?.join(",") === obj.rowIndices.join(",") &&
          rowIds?.join?.() !== rowIdsStr
        ) {
          delete this.data[elementId][rowIdsStr];
        }
      }
    }

    this.save(this.data);

    if (existing?.value !== obj.value) {
      setTimeout(() => {
        console.log({
          elementChangesListeners: this.elementChangesListeners,
          elementId,
          rowIds,
        });

        let elementData = this.elementChangesListeners.find(
          (x) => x.elementId === elementId && x.rowIds === rowIds?.join?.()
        );

        if (!elementData) {
          elementData = this.elementChangesListeners.find(
            (x) => x.elementId === elementId
          );
        }

        elementData?.subscribers?.map((subscriber) => {
          return subscriber?.fn?.();
        });
      }, 0);
    }
  }

  getRepeatingRowId({ containerId, rowId, rowIndex = 0 }) {
    if (rowId) return rowId.trim();

    try {
      const elmentInstances = this.data[containerId] || {};
      const containerInstance = Object.values(elmentInstances).find(
        (x) => x?.elementType === "container" && x?.value
      );

      const value = JSON.parse(containerInstance.value);

      const calcRowIndex = Math.max(rowIndex - 1, 0);

      const row = value[calcRowIndex] || {};

      return (row._id || calcRowIndex)?.toString()?.trim();
    } catch (error) {
      console.warn("error in getRepeatingRowIndex", error.message);
    }

    return null;
  }

  getRepeatingRowIndex({ containerId, rowId, rowIndex }) {
    if (rowIndex) return Math.max(rowIndex - 1, 0);

    if (rowId) {
      try {
        const elmentInstances = this.data[containerId] || {};
        const containerInstance = Object.values(elmentInstances).find(
          (x) => x?.elementType === "container" && x?.value
        );

        const value = JSON.parse(containerInstance.value);

        const index = value.findIndex?.(
          (x, i) => (x._id || i)?.toString()?.trim() === rowId.trim()
        );

        return index || 0;
      } catch (error) {
        console.warn("error in getRepeatingRowIndex", error.message);
      }
    }

    return 0;
  }

  getClosestElement({
    targetElementId,
    getterIndices,
    getterRowIndices,
    conditionFn,
    repeatingContainerRowInfo,
  }) {
    let targetIndices = this.dom.findRecursiveIndices(targetElementId) || [];
    targetIndices = [0, ...targetIndices];

    let repeatingContainerIndices = [];
    repeatingContainerRowInfo?.map((x) => {
      const indices = this.dom.findRecursiveIndices(x.containerId);
      repeatingContainerIndices.push({ ...x, indices: [0, ...indices] });
    });

    let targetRowIndices = [];
    for (let i = 0; i < targetIndices.length - 1; i++) {
      const targetIndex = targetIndices[i];
      const getterIndex = getterIndices[i];

      if (targetIndex === getterIndex && getterRowIndices.length > i) {
        const interveneRowInfoIndex = repeatingContainerIndices.findIndex(
          (x) => x.indices.length === i + 1
        );

        if (interveneRowInfoIndex > -1) {
          const interveneRowInfo = {
            ...repeatingContainerIndices[interveneRowInfoIndex],
          };
          targetRowIndices.push(this.getRepeatingRowIndex(interveneRowInfo));

          repeatingContainerIndices.splice(interveneRowInfoIndex, 1);
        } else {
          targetRowIndices.push(getterRowIndices[i]);
        }
      } else {
        break;
      }
    }

    const interveneRowIds = repeatingContainerIndices
      .map((x) => this.getRepeatingRowId(x))
      .filter((x) => x);

    let regex = new RegExp(`^${targetRowIndices.join()}.*`);

    let closestAndLatestElementInstance = null;
    const elementData = this.data[targetElementId];

    for (const rowIdsStr in elementData) {
      if (Object.hasOwnProperty.call(elementData, rowIdsStr)) {
        const rowIdsArray = rowIdsStr.split(",");

        if (!interveneRowIds.every((id) => rowIdsArray.includes(id))) continue;

        const elementInstance = elementData[rowIdsStr];

        const rowIndicesStr = elementInstance?.rowIndices?.join();

        if (
          (!conditionFn ||
            conditionFn({ elementInstance, rowIdsStr, elementData })) &&
          rowIndicesStr.match(regex) &&
          (!closestAndLatestElementInstance?.updatedAt ||
            elementInstance.updatedAt >
              closestAndLatestElementInstance.updatedAt)
        ) {
          closestAndLatestElementInstance = {
            ...elementInstance,
            rowIndicesStr,
          };
        }
      }
    }

    return closestAndLatestElementInstance;
  }

  unsubscribeToAllElementChanges(subscriberId) {
    this.elementChangesListeners = this.elementChangesListeners.map((node) => ({
      ...node,
      subscribers: node.subscribers.filter(
        (x) => x.subscriberId !== subscriberId
      ),
    }));
  }

  subscribeToElemntChanges(elements, subscriber) {
    const { subscriberId } = subscriber;

    for (let i = 0; i < elements.length; i++) {
      const elementId = elements[i].id;
      const rowIds = elements[i].rowIds?.join();

      const index = this.elementChangesListeners.findIndex(
        (x) => x.elementId === elementId && x.rowIds === rowIds
      );
      let listener = this.elementChangesListeners[index] || {
        elementId,
        rowIds,
        subscribers: [],
      };

      if (!listener.subscribers.find((x) => x.subscriberId === subscriberId)) {
        listener = {
          ...listener,
          subscribers: [...listener.subscribers, subscriber],
        };
      }

      if (index > -1) this.elementChangesListeners[index] = listener;
      else
        this.elementChangesListeners = [
          ...this.elementChangesListeners,
          listener,
        ];
    }
  }
}

export default ScreenDataStore;
