import React from "react";

import RenderTextInput from "./RenderTextInput";
import CustomSelect from "../../../../Components/etc/CustomSelect";

class RenderSelectBoxInput extends RenderTextInput {
  state = {
    visible: false,
  };

  renderInput(props = {}) {
    const selectedValue = this.toString(this.valueObj?.selected?.value);
    const options = this.valueObj?.options?.value;
    console.log({ options });

    return (
      // <div
      //   {...{
      //     value: selectedValue,
      //     style: this.styles?.input,
      //     ...props,
      //     onChange: (e) =>
      //       this.onChange({
      //         selected: { value: e.target.value },
      //         value: e.target.value,
      //       }),
      //   }}
      // >
      //   <option value="">Select</option>
      //   {options?.map?.((item) => (
      //     <option
      //       key={item.uid}
      //       label={item.label || item.value}
      //       value={item.value}
      //     />
      //   ))}
      // </div>

      <CustomSelect
        {...{
          style: this.styles?.input,
          styles: {
            head: {
              display: "flex",
              width: "100%",
              height: "100%",
              alignItems: "center",
            },
            label: {
              width: "100%",
              height: "100%",
            },
            chevron: {
              width: "10px",
              height: "10px",
              objectFit: "contain",
              marginRight: "16px",
              borderColor: this.styles?.input?.["--icon-color"],
            },
          },
          value: selectedValue,
          onChange: (option) =>
            this.onChange({
              selected: { value: option.value },
              value: option.value,
            }),
          options,
        }}
      />

      // <div
      //   className={`custom-select `}
      //   style={{
      //     ...withBorderStyle,
      //     ...inputStyle,
      //     position: "relative",
      //   }}
      // >
      //   <div
      //     className={`custom-select-head `}
      //     style={{
      //       display: "flex",
      //       justifyContent: "space-between",
      //       alignItems: "center",
      //       width: "100%",
      //       height: "100%",
      //     }}
      //   >
      //     <div className={`selected-option `} style={{ flex: 1 }}>
      //       Select
      //     </div>

      //     <Chevron className="optionDatabaseSelectChevron" />
      //   </div>
      // </div>
    );
  }
}

export default RenderSelectBoxInput;
