import React from "react";

import { connect } from "react-redux";
import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import { getDatastore } from "../../Providers/datastore";
import utils from "../../Modules/utils/utils";
import { LinkingValueTypes } from "../../appxolo-engine/modules/linking/linking-obj";
import callModule from "../../Modules/call/call-module";

//   if (this.state.streamControl) {
//     return (
//       <div>
//         <div>Mute</div>
//         <div onClick={() => this.setState({ streamControl: false })}>
//           Close
//         </div>
//       </div>
//     );
//   }

class StreamControl extends React.Component {
  state = {
    visible: true,
  };

  getLinkingFunctions() {
    const dataStoreData = getDatastore() || {};

    const setScreenState = (obj, persist, screenName = "APP") =>
      this.props.setScreenState(obj, persist, screenName);

    const _utils = utils({
      setScreenState,
    });

    const linkingValueTypes = new LinkingValueTypes({
      availableProps: {
        utils: _utils,
        ...dataStoreData,
      },
    });

    return linkingValueTypes.evalFunctions;
  }

  toggleCamera() {
    this.getLinkingFunctions().camera();
  }

  toggleMute() {
    this.getLinkingFunctions().microphone();
  }

  endCall() {
    callModule.endCall();
  }

  render() {
    const {
      props: { onGoingCall, isCameraDisabled, isMuted },
      state: { visible },
    } = this;

    if (onGoingCall?.visible && onGoingCall?.streamControl === "on") {
      if (visible) {
        return (
          <div>
            {onGoingCall?.permission === "video" ? (
              <div
                onClick={() => this.toggleCamera()}
                className={" " + (isCameraDisabled ? "disable" : "enable")}
              >
                Camera
              </div>
            ) : null}
            {["video", "audio"].includes(onGoingCall?.permission) ? (
              <div
                onClick={() => this.toggleMute()}
                className={" " + (isMuted ? "disable" : "enable")}
              >
                {isMuted ? "Unmute" : "Mute"}
              </div>
            ) : null}

            <div onClick={() => this.endCall()}>End Call</div>
            <div onClick={() => this.setState({ visible: false })}>Close</div>
          </div>
        );
      } else {
        return (
          <div>
            <div onClick={() => this.setState({ visible: true })}>
              Show Control
            </div>
          </div>
        );
      }
    }
  }
}

const SCREEN_NAME = "CALL_SCREEN";
const mapStateToProps = (state) => ({
  onGoingCall: state.vState[SCREEN_NAME]?.onGoingCall,
  isMuted: state.vState.APP?.isMuted,
  isCameraDisabled: state.vState.APP?.isCameraDisabled,
});

const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamControl);
