const utils = {
  getWeekDays: (locale = "en-US", option = {}) => {
    var baseDate = new Date(Date.UTC(2017, 0, 1)); // just a Monday
    var weekDays = [];
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        baseDate.toLocaleDateString(locale, {
          weekday: option.format || "short",
        })
      );
      baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
  },

  getFirstDayOfWeek: (date) => {
    const currentDate = new Date(date);
    const dayOfWeek = currentDate.getDay();
    const diff = dayOfWeek;
    currentDate.setDate(currentDate.getDate() - diff);

    return currentDate;
  },

  getFirstDayOfMonth: (date) => {
    const firstDayOfMonth = new Date(date);
    firstDayOfMonth.setDate(1);
    return firstDayOfMonth;
  },

  //   parseDate: (x) =>
  //     !x && x !== 0
  //       ? null
  //       : isNaN(x)
  //       ? new Date(x)
  //       : x.getTime
  //       ? new Date(x)
  //       : new Date(parseInt(x)),

  parseDate: (input) => {
    if (input === undefined || input === null) {
      return null;
    } else if (
      typeof input === "number" ||
      (typeof input === "string" && /^\d+$/.test(input))
    ) {
      return new Date(Number(input));
    } else if (input instanceof Date) {
      return new Date(input);
    } else if (typeof input === "string") {
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;
      if (timePattern.test(input)) {
        const parts = input.split(":");
        const hours = Number(parts[0]);
        const minutes = Number(parts[1]);
        const seconds = parts[2] ? Number(parts[2]) : 0;

        if (
          hours >= 0 &&
          hours <= 23 &&
          minutes >= 0 &&
          minutes <= 59 &&
          seconds >= 0 &&
          seconds <= 59
        ) {
          const currentDate = new Date();
          currentDate.setHours(hours, minutes, seconds, 0);
          return currentDate;
        }
      }

      return utils.parseLocalDate(input);
    }

    return null;
  },

  parseLocalDate: (dateString) => {
    if (!dateString) return null;
    const standardizedDateString = dateString
      .replace(/-/g, "/")
      .replace(/T/g, " ");
    const localDate = new Date(standardizedDateString);
    if (isNaN(localDate.getTime())) {
      console.warn("Invalid date string:", dateString);
      return null;
    }
    return localDate;
  },

  toDateOnlyFormat: (d) => {
    const date = new Date(d);

    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if (isNaN(dd)) dd = 0;
    if (isNaN(MM)) MM = 0;
    if (isNaN(yyyy)) yyyy = "0000";

    dd = dd < 10 ? "0" + dd.toString() : dd.toString();
    MM = MM < 10 ? "0" + MM.toString() : MM.toString();
    yyyy = yyyy.toString();

    return yyyy + "/" + MM + "/" + dd;
  },

  toViewableDateFormat: (d) => {
    const date = new Date(d);

    let dd = date.getDate();
    let MM = date.getMonth();
    let yyyy = date.getFullYear();

    if (isNaN(dd)) dd = 0;
    if (isNaN(MM)) MM = 0;
    if (isNaN(yyyy)) yyyy = "0000";

    yyyy = yyyy.toString();

    return `${utils.shortMonths[MM]} ${dd}, ${yyyy}`;
  },

  formatDateToYYYYMMDDHHMM: (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  },

  formatDateToHHMM: (date) => {
    if (!(date instanceof Date)) return null;
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  },

  formatDateToHHMMSS: (date) => {
    if (!(date instanceof Date)) return null;
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  },

  compareLocalTimeTo: (dateInstance, timeValue) => {
    const localHours = dateInstance.getHours();
    const localMinutes = dateInstance.getMinutes();

    const [givenHours, givenMinutes] = timeValue.split(":").map(Number);

    if (
      localHours < givenHours ||
      (localHours === givenHours && localMinutes < givenMinutes)
    ) {
      return -1;
    } else if (
      localHours > givenHours ||
      (localHours === givenHours && localMinutes > givenMinutes)
    ) {
      return 1;
    } else {
      return 0;
    }
  },

  getDateDetails(dateOrRange = new Date(), endDate = null) {
    const getSingleDateDetails = (date) => {
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const weekday = date.getDay();
      const hour = date.getHours();
      const minute = date.getMinutes();
      const second = date.getSeconds();
      const millisecond = date.getMilliseconds();
      const timezoneOffset = date.getTimezoneOffset();
      const isoString = date.toISOString();
      const localDateString = date.toLocaleDateString();
      const localTimeString = date.toLocaleTimeString();
      const utcDateString = date.toUTCString();
      const timestamp = date.getTime();
      const isLeapYear =
        year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);

      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return {
        date: localDateString,
        day,
        month: months[month],
        monthIndex: month,
        year,
        weekday: weekdays[weekday],
        weekdayIndex: weekday,
        hour,
        minute,
        second,
        millisecond,
        timezoneOffset,
        isoString,
        localDateString,
        localTimeString,
        utcDateString,
        timestamp,
        isLeapYear,
      };
    };

    if (endDate === null) {
      // Single Date Case
      return getSingleDateDetails(new Date(dateOrRange));
    } else {
      // Date Range Case
      const start = new Date(dateOrRange);
      const end = new Date(endDate);

      if (isNaN(start) || isNaN(end)) {
        throw new Error("Invalid date range provided");
      }

      const dayCount =
        Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) +
        1;

      return {
        dateRange: `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`,
        dayCount,
        startDate: start.toLocaleDateString(),
        startDay: start.getDate(),
        startMonth: start.getMonth() + 1,
        startMonthName: start.toLocaleString("default", { month: "long" }),
        startYear: start.getFullYear(),
        startWeekday: start.toLocaleString("default", { weekday: "long" }),
        startWeekdayIndex: start.getDay(),
        endDate: end.toLocaleDateString(),
        endDay: end.getDate(),
        endMonth: end.getMonth() + 1,
        endMonthName: end.toLocaleString("default", { month: "long" }),
        endYear: end.getFullYear(),
        endWeekday: end.toLocaleString("default", { weekday: "long" }),
        endWeekdayIndex: end.getDay(),
        startTimestamp: start.getTime(),
        endTimestamp: end.getTime(),
      };
    }
  },

  shortMonths: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};

export default utils;
