import React from "react";
import AppxoloMediaPlayer from "../../../../Components/etc/MediaPlayer/MediaPlayer";

const VideoMedia = (props) => {
  const { domNode, activeTab, elementDataStore } = props;

  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const value = elementDataStore?.value;

  return value ? (
    <AppxoloMediaPlayer
      {...{
        ...(elementDataStore?.playerConfig || {}),
        isAudioPlayer: true,
        src: value,
        style: { width: "100%", height: "100%", ...(activeTabStyle || {}) },
      }}
    />
  ) : null;
};

export default VideoMedia;
