import React from "react";
import api from "../../../../Services/Api/api";
import RenderFileInput from "./RenderFileInput";
import { toast } from "react-toastify";

class RenderImageInput extends RenderFileInput {
  constructor(props) {
    super(props);
    this.pickerType = "media";
  }
}

export default RenderImageInput;
